import { RequestError, RequestMethod } from "types/request";
import { RESPONSE_CODES } from "utils/constants/responseCodes";
import { InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";
import { msalInstance } from "utils/helpers/msal/AppMsalProvider";
import { getScope } from "authConfig";
import { appConfig } from "appConfig";
import fetchIntercept from "fetch-intercept";
import { reactPlugin } from "services/microsoftApplicationInsightsService";
import { isOnline } from "utils/helpers/isOnline";
import { wait } from "utils/waitPromise";
import { IAutoExceptionTelemetry, SeverityLevel } from "@microsoft/applicationinsights-web";

export const trackException = (type: string, message: string, url?: string, disable?: boolean) => {
  // Disable logging manually
  if (disable) return;

  reactPlugin.trackException(
    {
      exception: {
        message: message,
        url: url,
      } as IAutoExceptionTelemetry,
      severityLevel: SeverityLevel.Error,
    },
    { type: type }
  );
};

fetchIntercept.register({
  request: async function (url, options) {
    // If "regular" http requests, return
    if (
      url.includes(appConfig.authTenant) ||
      url.includes("hot-update") ||
      url.includes("analytics.google") ||
      url.includes("google-analytics")
    ) {
      return [url, options];
    }

    const accessToken = await getAccessToken(url);
    if (!accessToken) {
      const error = new Error("No access token aquired for: " + url);
      trackException("NoAccessTokenError", `No access token aquired`, url);
      throw error;
    }

    // Set default
    const defaultOptions = setOptions(options, accessToken);
    // Merge options
    const fetchOptions = { ...defaultOptions, ...options };

    return [url, fetchOptions];
  },

  requestError: function (error) {
    if (process.env.NODE_ENV === "development") console.log("requestError", error);
    // Called when an error occured during another 'request' interceptor call

    try {
      if (error instanceof TypeError || error instanceof Error) {
        const typeError = error as TypeError;

        trackException(
          "RequestError",
          `Request type error: ${typeError.name} - ${typeError.message} - ${typeError.stack}`
        );
      }

      if (error instanceof Response) {
        const response = error as Response;

        if (response.status !== 404)
          trackException("RequestError", `Request error: ${response.status} - ${response.statusText}`, response.url);
      }
    } catch (error) {}

    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    return response;
  },

  responseError: function (error) {
    if (process.env.NODE_ENV === "development") console.log("responseError", error);

    try {
      let err = error;
      if (error instanceof Object && error !== null) err = JSON.stringify(error);

      trackException("ResponseError", `Fetch error: ${err}`);
    } catch (error) {
      console.error("responseError catch", error);
    }

    // Handle an fetch error
    return Promise.reject(error);
  },
});

const getRequestError = async (response: Response | TypeError): Promise<RequestError> => {
  const isOffline = !isOnline();
  let errorObj: RequestError;

  if (process.env.NODE_ENV === "development") console.warn("getRequestError", response, isOffline);

  if (isOffline) {
    errorObj = {
      status: 555,
      title: "Du verkar vara offline",
      errors: { error: "device appears to be offline" },
    };
    return Promise.resolve(errorObj);
  }

  if (response instanceof TypeError) {
    const typeError = response as TypeError;

    errorObj = {
      status: 0,
      title: typeError.name,
      errors: typeError.message,
    };
    return Promise.resolve(errorObj);
  }

  let errorMessage: any;
  const contentType = response.headers?.get("Content-Type");
  try {
    const responseClone1 = response.clone();
    const isJson = contentType?.includes("application/json");
    errorMessage = isJson ? await responseClone1.json() : null;

    const responseClone2 = response.clone();
    const isText = contentType?.includes("text/plain");
    errorMessage = isText ? await responseClone2.text() : { error: "no error message available" };
  } catch (error) {
    errorMessage = { error: error };
  }
  errorObj = {
    status: response.status,
    title: response.statusText,
    errors: errorMessage,
  };
  return Promise.resolve(errorObj);
};

const getAccessToken = async (url: string) => {
  let tokenRequest: SilentRequest;

  const account = msalInstance.getActiveAccount();
  if (!account) throw new Error("No active account");

  try {
    const scopes = getScope(url!);
    if (!scopes) {
      if (process.env.NODE_ENV === "development") console.log("no scopes defined for", url);
      trackException("NoScopesForUrlError", `No scopes defined for ${url}`);
      return undefined;
    }

    tokenRequest = {
      scopes: scopes,
      account: account,
    };
    const result = await msalInstance.acquireTokenSilent(tokenRequest);

    return result.accessToken;
  } catch (error: any) {
    if (error instanceof InteractionRequiredAuthError) {
      if (process.env.NODE_ENV === "development") console.log("InteractionRequiredAuthError", error);
      trackException("InteractionRequiredAuthError", `InteractionRequiredAuthError: ${error}`, url);

      const redirectRequest = {
        scopes: tokenRequest!.scopes,
        loginHint: account.username,
      };
      return await msalInstance.acquireTokenRedirect(redirectRequest);
    } else {
      if (process.env.NODE_ENV === "development") console.log("getToken error:", error);
      trackException("UnknownTokenError", `UnknownTokenError: ${error}`, url);
    }
    return undefined;
  }
};

const setOptions = (init: RequestInit | undefined, accessToken?: string) => {
  const options: RequestInit = {};
  const headers = new Headers();

  setDefaultHeaders(init?.method);
  setAuthHeader(accessToken);

  options.headers = headers;

  function setDefaultHeaders(method?: string) {
    headers.append("X-Requested-With", "XMLHttpRequest");

    if (method && (method === "POST" || method === "PUT" || method === "PATCH"))
      headers.append("Content-Type", "application/json");
  }

  function setAuthHeader(accessToken: string | null | undefined) {
    if (accessToken) headers.append("Authorization", `Bearer ${accessToken}`);
  }

  return options;
};

type Parameters = {
  url: string;
  options?: RequestInit;
  numberOfRetries?: number;
  delay?: number;
};

/**
 * Retries a fetch request with optional retries and delay.
 *
 * @param url - The URL to fetch.
 * @param options - The options for the fetch request.
 * @param numberOfRetries - The number of retries (default: 3).
 * @param delay - The delay between retries in milliseconds (default: 0).
 * @returns A Promise that resolves to the fetch response.
 * @throws If there are too many fetch attempts on the URL.
 */
const retries = 5;
async function fetchRetry({ url, options, numberOfRetries = retries, delay = 0 }: Parameters): Promise<Response> {
  if (numberOfRetries === 0) {
    trackException("FetchRetryError", `Too many failed fetch attempts (${retries} attempts)`, url);

    return Promise.reject("Too many failed fetch attempts on " + url);
  }

  return await fetch(url, options)
    .then(async (response) => {
      if (!response.ok) return Promise.reject(response);

      return response;
    })
    .catch(async (error: Response) => {
      if (process.env.NODE_ENV === "development") console.log("fetchRetry error", error, numberOfRetries, url);

      // If error is not 0 or 502, handle error the "normal" way.
      if (!(error instanceof TypeError) && error.status !== 0 && error.status !== 502) throw error;

      trackException("FetchRetryError", `Retry attempt nr ${retries + 1 - numberOfRetries}`, url);

      if (numberOfRetries !== 0) {
        delay = delay + 500;
        await wait(delay);
        return await fetchRetry({ url, options, numberOfRetries: numberOfRetries - 1, delay: delay });
      }

      throw error;
    });
}

export const get = async (url: string): Promise<any> => {
  return await fetchRetry({ url })
    .then(async (response) => {
      if (response.status === RESPONSE_CODES.OK_NO_CONTENT) return null;

      return responseAsJson(response);
    })
    .catch(async (response) => await getRequestError(response));
};

export const remove = async (url: string): Promise<any> => {
  const options = {
    method: "DELETE",
  };

  return await fetchRetry({ url, options })
    .then(async (response) => {
      return response;
    })
    .catch(async (response) => await getRequestError(response));
};

export const put = async (url: string, data: any) => {
  const options = {
    method: "PUT",
    body: JSON.stringify(data),
  };

  return await fetchRetry({ url, options })
    .then(async (response) => {
      if (response.status !== RESPONSE_CODES.OK_NO_CONTENT) return responseAsJson(response);

      return response;
    })
    .catch(async (response) => await getRequestError(response));
};

type RequestMethodPostPatch = Extract<RequestMethod, "POST" | "PATCH">;
export const post = async (url: string, data: any, method: RequestMethodPostPatch = "POST") => {
  const options: RequestInit = {
    method: method,
    body: JSON.stringify(data),
  };

  return await fetchRetry({ url, options })
    .then((response) => {
      if (response.status !== RESPONSE_CODES.OK_NO_CONTENT) return responseAsJson(response);

      return response;
    })
    .catch(async (response) => await getRequestError(response));
};

/**
 * Make sure the response is valid JSON.
 * @param response The response object.
 * @returns A promise that resolves to the JSON representation of the response.
 */
const responseAsJson = async (response: Response) => {
  try {
    const responseClone = response.clone();
    const text = await responseClone.text();
    return JSON.parse(text);
  } catch (err) {
    if (process.env.NODE_ENV === "development") console.log("responseAsJson error", err);

    trackException("JSONParseError", `JSONParseError: ${err}`, response.url);

    return Promise.reject(response);
  }
};
