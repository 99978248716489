import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { learningStructureState } from "state/learningStructureState";
import { educationStateReferenceKey } from "state/educationState";
import { getProgressReport } from "services/lrsService";
import { useAssessment } from "./useAssessment";
import {
  ItemConfigurationTypeKnowledge,
  ItemTypeKnowledge,
  ItemKnowledgeDetail,
  ItemTitleValuePair,
} from "services/reportingService";
import { userStateAccount } from "state/userState";

/**
 * Hook for getting progress data from the current education
 */
export const useProgress = () => {
  const account = useRecoilValue(userStateAccount);
  const [knowledgeLevels, setKnowledgeLevels] = useState<undefined | ItemTitleValuePair[]>();
  const [knowledgeLevelUser, setKnowledgeLevelUser] = useState<undefined | number>();
  const learningStructure = useRecoilValue(learningStructureState);
  const { assessment: assessmentMaster } = useAssessment({
    type: "study",
    key: useRecoilValue(educationStateReferenceKey)!,
  });

  useEffect(() => {
    const referenceKey = learningStructure?.find((x) => x.parentId === 0)?.activityKey;
    if (!assessmentMaster || !account || !referenceKey) return;

    const getProgress = async () => {
      const educationProgress = await getProgressReport(assessmentMaster, account.actorId, referenceKey);

      const knowledgeLevels = educationProgress.items.filter(
        (item: any) =>
          item.type === ItemTypeKnowledge.KnowledgeLevel &&
          item.configuration.type === ItemConfigurationTypeKnowledge.DetailA
      ) as ItemKnowledgeDetail[];
      setKnowledgeLevels(knowledgeLevels[0].items);

      const knowledgeLevel = educationProgress.items.filter(
        (item: any) =>
          item.type === ItemTypeKnowledge.KnowledgeLevel &&
          item.configuration.type === ItemConfigurationTypeKnowledge.OverviewA
      );
      const value = knowledgeLevel[0].items[0].value;
      setKnowledgeLevelUser(parseInt(value === "" ? "0" : value));
    };
    getProgress();
  }, [assessmentMaster, learningStructure, account]);

  return { knowledgeLevels, knowledgeLevelUser };
};
