import { TypographyOptions } from "@mui/material/styles/createTypography";
import { fontFamily, fontSize } from "./optima";

export const typographyLearning: TypographyOptions = {
  fontFamily: fontFamily,
  h2: {
    fontSize: fontSize.h2,
    lineHeight: fontSize.h2,
    fontWeight: 700,
    textTransform: "uppercase",
    letterSpacing: "0.5px",
    marginBottom: "1.5rem",
  },
};
