import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { theoryChapterState } from "state/theoryState";
import { LRSProvider, LRSProviderState, LRSRecord } from "@strmediaochitab/optima-component-library";
import {
  activitiesStateSelector,
  educationContentTypeState,
  selectedActivitesKeyState,
} from "state/learningStructureState";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { scrollToElementByLocation } from "utils/helpers/ScrollHandler";
import { testId } from "tests/testIdStrings";
import { FillBox, RenderActivities } from "utils/helpers/theory/RenderActivities";
import { getLrsState, XapiKey } from "services/lrsService";
import { NoContent } from "utils/helpers/theory/NoContent";
import { useTextToSpeech } from "hooks/useTextToSpeech";
import { useAppContext } from "context/AppContext";
import { typographyLearning } from "theme/themeLearning";
import { userStateAccount } from "state/userState";
import useMediaQueries from "hooks/useMediaQueries";

interface TheoryReadProps {}
export const TheoryRead: React.FC<TheoryReadProps> = (props) => {
  const theme = useTheme();
  const { soundSupport } = useAppContext();
  const chapter = useRecoilValue(theoryChapterState);
  const location = useLocation();
  const account = useRecoilValue(userStateAccount);
  const { isMobile } = useMediaQueries();
  const activities = useRecoilValue(
    activitiesStateSelector({
      keys: useRecoilValue(selectedActivitesKeyState),
      classificationId: useRecoilValue(educationContentTypeState).get("read")!,
    })
  );
  const initialState = new Map<string, LRSProviderState>();
  const [lrsState, setLrsState] = useState<LRSRecord>(initialState);
  const { start, stop, speechInProgress } = useTextToSpeech();

  const xapiKey: XapiKey = {
    userId: account!.actorId,
    contentId: chapter!.activityKey!.contentId,
    versionId: chapter!.activityKey!.versionId!,
    stateId: useRecoilValue(educationContentTypeState).get("read")!,
  };

  const hasMaterial = useMemo(() => {
    return activities?.some((activity) => activity.materialReferences?.length !== 0);
  }, [activities]);

  useEffect(() => {
    const getState = async () => {
      // TODO: Vi bör separera assessment även här. Men just nu baseras det på att vi först hämtat ut vår assessment :(
      const lrsState = await getLrsState(xapiKey);
      setLrsState(lrsState);
    };

    getState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter!.id]);

  useEffect(() => {
    if (!activities?.length) return;
    if (!lrsState) return;

    scrollToElementByLocation({ location, offsetTop: -100 });
  }, [location, activities, lrsState]);

  useEffect(() => {
    ReactGA.event({
      category: "theory",
      action: "read",
    });
  }, []);

  if (!account) {
    console.log("No active account");
    return null;
  }

  // Make sure we wait for LRS before initializing the LRSProvider
  if (!lrsState) return null;

  if (hasMaterial === false)
    return (
      <>
        <Typography variant="h1" data-testid={testId.listItem.theory.articleTitle}>
          {chapter?.title}
        </Typography>
        <NoContent />
      </>
    );

  return (
    <Box mx={theme.spacing(2)}>
      <LRSProvider
        user={account.actorId}
        textToSpeech={
          soundSupport
            ? {
                start: start,
                stop: stop,
                speechInProgress: speechInProgress,
              }
            : undefined
        }
        callbackSetQuestion={() => {}}
        state={{ questions: lrsState }}
        themeOptions={{ typography: typographyLearning, palette: theme.palette, shape: theme.shape }}
      >
        <FillBox>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {isMobile && (
              <Typography marginTop="56px" variant="h1" mb={0} data-testid={testId.listItem.theory.articleTitle}>
                {chapter?.title}
              </Typography>
            )}
          </Box>
        </FillBox>

        <RenderActivities
          activities={activities}
          // navigateTo={TheoryTab.practice}
          // bgColor={optimaColors.secondary01}
          type="read"
        />
      </LRSProvider>
    </Box>
  );
};
