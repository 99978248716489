import { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack, Typography, Button, SvgIconTypeMap, Divider, useTheme, IconButton } from "@mui/material";
import { Icon, iconBookmarkSolid, iconPen, iconTrashcan, iconArrowRightRegular } from "theme/icons";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { bookmarksStateSelector } from "state/bookmarkState";
import { EditBookmarkDialog } from "./editBookmarkDialog";
import { RenderActivities } from "utils/helpers/theory/RenderActivities";
import { activitiesStateSelector, educationContentTypeState } from "state/learningStructureState";
import {
  ItemQuestion,
  LRSProvider,
  LRSProviderState,
  LRSRecord,
  Question,
} from "@strmediaochitab/optima-component-library";
import { API_URL_CDS_COMPOSITE } from "utils/constants";
import { get } from "services/httpService";
import { DeleteBookmarkDialog } from "./deleteBookmarkDialog";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { Activity, TreeNode } from "types/cds";
import { theoryAreaState, theoryChapterState, theoryState, theoryStateSelected } from "state/theoryState";
import { BookmarkState } from "services/appStateService";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import useChapterActivities from "hooks/useChapterActivities";
import { typographyLearning } from "theme/themeLearning";
import { userStateAccount } from "state/userState";
import useMediaQueries from "hooks/useMediaQueries";

// Helper function to get the path to the bookmarked activity
const getPath = (
  activity: Activity,
  structure: TreeNode[],
  type: BookmarkState["type"],
  routes: ReturnType<typeof useRouteConfiguration>
) => {
  const node = structure.find((s) => s.activityKey?.contentId === activity.key.contentId);
  if (!node) return null;

  const chapter = structure.find((s) => s.id === node.parentId);
  if (!chapter) return null;

  let path = "";
  const chapterId = chapter.id.toString();
  const areaId = chapter.parentId.toString();

  switch (type) {
    case "practice":
      path = routes.theoryPractice.path;
      break;

    case "answer":
      path = routes.theoryAnswer.path;
      break;

    default:
      path = routes.theoryRead.path;
      break;
  }

  path = path.replace(":areaId", areaId).replace(":chapterId", chapterId);
  console.log("path", path);
  return path;
};

const initialState = new Map<string, LRSProviderState>();
type BookmarkDetailProps = {
  bookmark: BookmarkState;
};

export const BookmarkDetail = () => {
  const { id } = useParams();
  const [params] = useSearchParams();
  const type = params.get("type");
  const myBookmarks = useRecoilValue(bookmarksStateSelector);
  const bookmark = myBookmarks?.filter((item) => item.key.contentId === id && item.type === type)[0];

  console.log("bookmark", bookmark);

  if (!bookmark) return null;

  return (
    <>
      <BookmarkDetailBookmark bookmark={bookmark} />

      {bookmark.type === "answer" || bookmark.type === "study" || bookmark.type === "roadsigns" ? (
        <BookmarkDetailQuestion bookmark={bookmark} />
      ) : (
        <BookmarkDetailActivity bookmark={bookmark} />
      )}
    </>
  );
};

const BookmarkDetailBookmark = ({ bookmark }: BookmarkDetailProps) => {
  const theme = useTheme();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const { isMobile } = useMediaQueries();

  return (
    <>
      <Stack spacing={3}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignContent: "flex-start", alignItems: "center" }}>
            <Icon name={iconBookmarkSolid} size="large" color={bookmark.color as SvgIconTypeMap["props"]["color"]} />
            <Typography variant="subtitle2" ml={1}>
              {bookmark.date}
            </Typography>
          </Box>
          <IconButton sx={{ justifyContent: "flex-end" }} onClick={() => setShowDeleteDialog(true)}>
            <Icon name={iconTrashcan} color={"info"} />
          </IconButton>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: `${theme.shape.borderRadius}px`,
            border: "1px solid #0000001A",
            padding: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack spacing={1} pl={2}>
            <FormattedTypography variant="subtitle1" id="bookmarks.detail.title" />
            <Typography variant="subtitle2" color={"#00000099"}>
              {bookmark.text}
            </Typography>
          </Stack>
          <IconButton sx={{ alignSelf: "flex-start", padding: 0 }} onClick={() => setShowEditNoteModal(true)}>
            <Icon name={iconPen} color="info" />
          </IconButton>
        </Box>
        {isMobile && (
          <Divider
            sx={{ paddingTop: 3, ml: `${theme.spacing(-2)} !important`, mr: `${theme.spacing(-2)} !important` }}
          />
        )}
      </Stack>

      <EditBookmarkDialog
        open={showEditNoteModal}
        close={() => setShowEditNoteModal(false)}
        selectedBookmark={bookmark}
      />
      <DeleteBookmarkDialog
        open={showDeleteDialog}
        close={() => setShowDeleteDialog(false)}
        selectedBookmark={bookmark}
      />
    </>
  );
};

const BookmarkDetailActivity = ({ bookmark }: BookmarkDetailProps) => {
  const [lrsState] = useState<LRSRecord>(initialState);
  const { isMobile } = useMediaQueries();
  const account = useRecoilValue(userStateAccount);
  const navigate = useNavigate();
  const theme = useTheme();
  const routes = useRouteConfiguration();
  const { setChapterActivities } = useChapterActivities();
  const setAreaState = useSetRecoilState(theoryAreaState);
  const setChapterState = useSetRecoilState(theoryChapterState);
  const setTheorySelected = useSetRecoilState(theoryStateSelected);
  const learningStructure = useRecoilValue(theoryState);
  const keys = learningStructure
    .filter((t) => t.activityKey?.contentId !== undefined && t.parentId !== 0)
    .map((t) => t.activityKey!);

  const classificationId = useRecoilValue(educationContentTypeState).get(bookmark.type)!;
  const activities = useRecoilValue(
    activitiesStateSelector({
      keys: keys,
      classificationId: classificationId,
    })
  );

  function navigateToActivity(activity: Activity) {
    const path = getPath(activity, learningStructure, bookmark.type, routes);
    if (!path) throw new Error("Path not found");

    // Set the bookmark activities before navigating
    const section = learningStructure.find((ls) => ls.activityKey?.contentId === activity.key.contentId);
    const chapter = learningStructure.find((ls) => ls.id === section?.parentId);
    const area = learningStructure.find((ls) => ls.id === chapter?.parentId);

    if (!area || !chapter) throw new Error("Area or chapter not found in learning structure");

    setTheorySelected(area);
    setAreaState(area);
    setChapterState(chapter);
    setChapterActivities(chapter);

    navigate(path + "#" + bookmark.key.contentId);
  }

  const myActivity = activities?.find((a) => a.key.contentId === bookmark?.key.contentId);
  if (!myActivity) return null;

  return (
    <>
      <Box
        display="flex"
        sx={{
          justifyContent: "flex-end",
          marginTop: `${isMobile ? "1rem" : "2rem"}`,
          marginBottom: `${isMobile ? "-2rem" : 0}`,
        }}
      >
        <Button variant="text" color="info" onClick={() => navigateToActivity(myActivity)}>
          <FormattedTypography
            id="bookmarks.to.episode"
            variant="button"
            sx={{ display: "flex", alignItems: "center" }}
          />
          <Icon name={iconArrowRightRegular} />
        </Button>
      </Box>

      <LRSProvider
        user={account!.actorId}
        callbackSetQuestion={() => {}}
        state={{ questions: lrsState }}
        themeOptions={{ typography: typographyLearning, palette: theme.palette, shape: theme.shape }}
      >
        <RenderActivities activities={[myActivity]} bgColor={theme.palette.secondary.light01} type={bookmark.type} />
      </LRSProvider>
    </>
  );
};

const BookmarkDetailQuestion = ({ bookmark }: BookmarkDetailProps) => {
  const [lrsState] = useState<LRSRecord>(initialState);
  const account = useRecoilValue(userStateAccount);
  const [testQuestion, setTestQuestion] = useState<ItemQuestion | null>(null);

  useEffect(() => {
    const getQuestion = async () => {
      const key = bookmark.key;
      const url = `${API_URL_CDS_COMPOSITE}${key.contentId}?versionId=${key.versionId}`;

      const question: ItemQuestion = await get(url).then((data: any) => data);
      setTestQuestion(question);
    };

    getQuestion();
  }, [bookmark]);

  if (!testQuestion) return null;

  return (
    <Box mt={4}>
      <LRSProvider user={account!.actorId} callbackSetQuestion={() => {}} state={{ questions: lrsState }}>
        <Question question={testQuestion} renderButton={false} />
      </LRSProvider>
    </Box>
  );
};
