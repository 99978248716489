import { selectorFamily } from "recoil";
import { getTestResultOverview } from "services/lrsService";
import { generateOverview } from "layout/test/testResult";
import { IOverviewQuestion } from "utils/helpers/theory/QuestionOverview";
import { dataMultipleByContentAndVersionState } from "./learningStructureState";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { ExtendedActivity } from "types/cds";
import { ResultOverview } from "services/reportingService";

// TODO: Kanske ta bort? Väljer i nuläget att skippa recoil för denna state.

// export const progressState = atom<null | any>({
//   key: "progresData",
//   default: {},
//   effects: [localStorageEffect("progressData")],
// });

// export const testResults = selector<[TestResultObject] | []>({
//   key: "progressRefined",
//   get: ({ get }) => {
//     const progressData = get(progressState);
//     if (!progressData) return undefined;
//     const testResult = progressData?.items[2].items;
//     return testResult;
//   },
// });

// export const progressLevels = selector<[ProgressLevelObject] | undefined>({
//   key: "progressLevels",
//   get: ({ get }) => {
//     const progressData = get(progressState);
//     if (!progressData) return undefined;
//     const progressLevels = progressData?.items[1].items;
//     return progressLevels;
//   },
// });

export const testResultState = selectorFamily<
  { questions: IOverviewQuestion[]; results: ResultOverview[] } | undefined,
  string | undefined
>({
  key: "testResults",
  get:
    (assessmentId) =>
    async ({ get }) => {
      if (!assessmentId) return undefined;

      const reportOverview = await getTestResultOverview(assessmentId);
      const questions = generateOverview(reportOverview);

      return { questions, results: reportOverview };
    },
});

export const studyHelpState = selectorFamily<any | undefined, ReferenceKey[]>({
  key: "studyHelpState",
  get:
    (keys) =>
    async ({ get }) => {
      const activitiesData = get(dataMultipleByContentAndVersionState({ keys }));

      if (!activitiesData) return undefined;

      const activitiesExtended: ExtendedActivity[] = activitiesData.map(
        (a: { data: string; contentId: string; versionId: string }) => {
          const activity: ExtendedActivity = JSON.parse(a.data);
          activity.contentId = a.contentId;
          activity.versionId = a.versionId;
          return activity;
        }
      );

      console.log("activitiesData", activitiesExtended);

      return activitiesExtended;
    },
});
