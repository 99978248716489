import { IconButton, Stack, Typography } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from "layout/Components/AccordionTable";
import ItemContainer from "layout/educationcard/ItemContainer";
import { Icon, iconChevronDown, iconPen } from "theme/icons";
import { defaultBorderColor } from "theme/theme";
import StudentEducation from "./Education";
import { useState } from "react";
import { LearningEventMaterial, LearningEventStudent } from "types/learningEvent";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import Password from "./Password";

type StudentProps = {
  student: LearningEventStudent;
  educations: LearningEventMaterial[];
};

const Student = ({ student, educations }: StudentProps) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>(false);
  const name = `${student.extensions?.firstName} ${student.extensions?.lastName}`;

  const handleExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const target = event.target as HTMLElement;

    // Prevent expanding when clicking on buttons or inputs
    if (target.tagName === "INPUT" || target.tagName === "BUTTON") return;
    if (target.tagName === "svg" || target.tagName === "path") {
      if (target.parentElement?.tagName === "BUTTON") return;
      if (target.parentElement?.tagName === "svg") {
        if (target.parentElement?.parentElement?.tagName === "BUTTON") return;
        if (target.parentElement?.parentElement?.parentElement?.tagName === "BUTTON") return;
      }
    }

    setExpanded(isExpanded ? panel : false);
  };

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate(`${student.id}`);
  };

  return (
    <Accordion
      disableGutters
      onChange={handleExpand(`panel-${student.id}`)}
      expanded={expanded === `panel-${student.id}`}
    >
      <AccordionSummary
        expandIcon={<Icon name={iconChevronDown} color="info" />}
        aria-controls={`panel-${student.id}-content`}
        id={`panel-${student.id}-header`}
      >
        <ItemContainer>
          <Typography variant="subtitle2">{name}</Typography>
          <Stack spacing={0.5} direction="row" alignItems="center" mr={1}>
            <Typography variant="subtitle2">{student.extensions?.identityNumber}</Typography>

            <IconButton color="info" onClick={handleEdit}>
              <Icon name={iconPen} />
            </IconButton>
            <Password id={student.id} name={name} identityNumber={student.extensions.identityNumber} />
            <Delete id={student.id} name={name} />
          </Stack>
        </ItemContainer>
      </AccordionSummary>
      <AccordionDetails
        sx={{ borderTop: `1px solid ${defaultBorderColor}`, ".MuiListItemButton-root": { minHeight: "auto" } }}
      >
        <Stack direction={"column"} spacing={0}>
          {educations.map((education) => (
            <StudentEducation key={education.versionId} education={education} student={student} />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default Student;
