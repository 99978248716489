import { useState } from "react";
import { Icon, iconAward } from "theme/icons";
import { Stack, Typography } from "@mui/material";
import Status from "./Status";
import ItemContainer from "./ItemContainer";
import Comment from "./Comment/Comment";
import FormSwitch from "utils/helpers/FormSwitch";
import useRoles from "hooks/useRoles";
import { LoadingStatus } from "types/request";
import { useRecoilValue } from "recoil";
import { educationCardPropsState } from "state/educationCardState";
import LearningObjective from "./LearningObjective";
import { EducationCardActivity, LearningObjectiveType } from "types/educationCard";
import AccordionEducationCard from "layout/Components/AccordionEducationCard";
import Students from "./Students";
import ButtonPdf from "./Pdf";

const ItemTestPractical = ({ item }: { item: EducationCardActivity }) => {
  // const { item, upsertListItem } = useEducationCardListItem(id);
  const [status, setStatus] = useState<LoadingStatus>("none");
  const { isEducator } = useRoles();
  const state = useRecoilValue(educationCardPropsState);

  if (!state) throw new Error("No education card state found");

  // TODO: PDF link should be dynamic and set on item
  const itemPdf = process.env.PUBLIC_URL + "/pdf/Provnyckel-forarbevis-3.0.pdf";

  // Approve all learningObjectives in this item
  async function handleApproveAll(checked: boolean) {
    if (!state) throw new Error("No education card state found");

    console.log("Approving", item.title);
    setStatus("pending");

    // collect all children
    // let theoreticalRefKeys = item.theoryItems?.map((i) => i.referenceKey) || [];
    // let updatedItem = await setApproved(
    //   state.eventId,
    //   item.rootKey,
    //   item.parentKey,
    //   theoreticalRefKeys,
    //   state.userIds,
    //   "theoretical",
    //   checked,
    //   item.learningObjectives,
    //   state.identifier
    // );

    // let practicalRefKeys = item.practiceItems?.map((i) => i.referenceKey) || [];
    // updatedItem = await setApproved(
    //   state.eventId,
    //   item.rootKey,
    //   item.parentKey,
    //   practicalRefKeys,
    //   state.userIds,
    //   "practical",
    //   checked,
    //   item.learningObjectives,
    //   state.identifier
    // );

    // TODO: handle errors...

    // upsertListItem(updatedItem);

    setStatus("none");
    console.log(item.title, "is", checked ? "approved" : "not approved");
  }

  // Approve a single learningObjective
  const handleApproveItem = async (item: EducationCardActivity, type: LearningObjectiveType, checked: boolean) => {
    if (!state) throw new Error("No education card state found");

    console.log("Approving", item.title);
    setStatus("pending");

    // const updatedItem = await setApproved(
    //   state.eventId,
    //   item.rootKey,
    //   item.parentKey,
    //   [item.referenceKey],
    //   state.userIds,
    //   type,
    //   checked,
    //   item.learningObjectives,
    //   state.identifier
    // );

    // TODO: handle errors...

    // upsertListItem(updatedItem);

    setStatus("none");
    console.log(item.title, "is", checked ? "approved" : "not approved");
  };

  return (
    <AccordionEducationCard
      id={item.id}
      status={status}
      summary={
        <ItemContainer>
          <Stack spacing={1} direction="row" alignItems="center">
            <Icon name={iconAward} color="info" />
            <Typography variant="subtitle2">{item.title}</Typography>
            {isEducator && <ButtonPdf pdf={itemPdf} />}
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            {isEducator ? (
              <>
                {/* <Status status={item.status} /> */}
                {state.type !== "education" && (
                  <FormSwitch label="Godkänd" checked={item.approved} onChange={handleApproveAll} />
                )}
                {state.type !== "education" && <Comment hasComment={false} noButton />}
              </>
            ) : (
              <Status status={item.status} />
            )}
            {state.type === "education" && <Students item={item} />}
          </Stack>
        </ItemContainer>
      }
      details={
        <Stack direction={"column"} spacing={4}>
          <Stack spacing={0}>
            {item.practiceItems?.map((subItem) => (
              <LearningObjective key={subItem.id} item={subItem} type={"practical"} onApprove={handleApproveItem} />
            ))}
          </Stack>
        </Stack>
      }
    />
  );
};

export default ItemTestPractical;
