import { Stack } from "@mui/material";
import { useAppContext } from "context/AppContext";
import { useFetch } from "hooks/useFetch";
import { useSetRecoilState } from "recoil";
import { isLanguageSupported, SupportedLanguages } from "services/localizationService";
import { groupUnit } from "state/educatorState";
import { iconArrowRightRegular } from "theme/icons";
import { LearningEvent } from "types/learningEvent";
import { API_URL_LES } from "utils/constants";
import { NavigationItem } from "utils/helpers/NavigationItem";

const List = () => {
  const { json: groups } = useFetch<LearningEvent[]>(`${API_URL_LES}me`);
  const setGroupUnit = useSetRecoilState(groupUnit);
  const { setActiveLanguage } = useAppContext();

  const handleGroupClick = (group: LearningEvent) => {
    console.log("group is", group);

    // Set language
    let language = group.extensions?.term ? `sv:${group.extensions.term}` : "sv";
    if (!isLanguageSupported(language)) {
      console.warn("language not supported", language, "defaulting to sv");
      language = "sv";
    }

    setActiveLanguage(language as SupportedLanguages);
    setGroupUnit(group.initiator.id2);
  };

  console.log("group events", groups);

  return (
    <Stack spacing={1.5}>
      {groups?.map((event) => (
        <NavigationItem
          key={event.id}
          text={event.name}
          iconSize="medium"
          navigationIcon={iconArrowRightRegular}
          href={`/group/${event.id}`}
          onClick={() => handleGroupClick(event)}
        />
      ))}
    </Stack>
  );
};

export default List;
