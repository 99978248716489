import { FC, useEffect, useState } from "react";
import { Box, Button, Typography, Menu } from "@mui/material";
import {
  Icon,
  iconTheory,
  iconTheoryActive,
  iconHome,
  iconHomeActive,
  iconTest,
  iconTestActive,
  iconMoreCircle,
  iconMoreActive,
  iconProgress,
  iconProgressActive,
} from "theme/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppIntl } from "services/useAppIntl";
import { More as MoreMenu } from "layout/more";
import useRoles from "hooks/useRoles";

const paths = {
  home: "/",
  theory: "/theory",
  test: "/test",
  progress: "/progress",
  more: "/more",
};

export const HeaderDesktopMenu: FC = () => {
  const location = useLocation();
  const intl = useAppIntl();
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const { isEducator } = useRoles();

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  if (isEducator) return null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "auto",
          marginRight: "auto",
          width: "40%",
          justifyContent: "space-evenly",
        }}
      >
        <Button onClick={() => navigate(paths.home)}>
          <Icon name={value === paths.home ? iconHomeActive : iconHome} htmlColor="white" />
          <Typography
            variant="caption"
            marginLeft={1}
            color={"white"}
            fontWeight={value === paths.home ? 700 : 400}
            textTransform="none"
          >
            {intl.formatMessage({ id: "common.title.home" })}
          </Typography>
        </Button>
        <Button onClick={() => navigate(paths.theory)}>
          <Icon name={value.includes(paths.theory) ? iconTheoryActive : iconTheory} htmlColor="white" />
          <Typography
            variant="caption"
            marginLeft={1}
            color={"white"}
            fontWeight={value.includes(paths.theory) ? 700 : 400}
            textTransform="none"
          >
            {intl.formatMessage({ id: "common.title.theory" })}
          </Typography>
        </Button>
        <Button onClick={() => navigate(paths.test)}>
          <Icon name={value === paths.test ? iconTestActive : iconTest} htmlColor="white" />
          <Typography
            variant="caption"
            marginLeft={1}
            fontWeight={value === paths.test ? 700 : 400}
            color={"white"}
            textTransform="none"
          >
            {intl.formatMessage({ id: "common.title.test" })}
          </Typography>
        </Button>
        <Button onClick={() => navigate(paths.progress)}>
          <Icon name={value.includes(paths.progress) ? iconProgressActive : iconProgress} htmlColor="white" />
          <Typography
            variant="caption"
            marginLeft={1}
            color={"white"}
            fontWeight={value.includes(paths.progress) ? 700 : 400}
            textTransform="none"
          >
            {intl.formatMessage({ id: "common.title.progress" })}
          </Typography>
        </Button>
        <Button onClick={() => setMoreMenuOpen(!moreMenuOpen)}>
          <Icon name={moreMenuOpen ? iconMoreActive : iconMoreCircle} htmlColor="white" />
          <Typography
            variant="caption"
            marginLeft={1}
            color={"white"}
            textTransform="none"
            fontWeight={moreMenuOpen ? 700 : 400}
          >
            {intl.formatMessage({ id: "common.title.more" })}
          </Typography>
        </Button>
        <Menu open={moreMenuOpen} onClose={() => setMoreMenuOpen(false)}>
          <MoreMenu desktopMenu />
        </Menu>
      </Box>
    </>
  );
};
