import { Box, useTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { theoryChapterState } from "state/theoryState";
import { EducationContentTypes, educationContentTypeState } from "state/learningStructureState";
import { XapiKey } from "services/lrsService";
import { useAssessment } from "hooks/useAssessment";
import { Test, TestLabel } from "layout/test/test";
import { NoContent } from "utils/helpers/theory/NoContent";
import { userStateAccount } from "state/userState";
import useMediaQueries from "hooks/useMediaQueries";

interface TheoryAnswerProps {}
export const TheoryAnswer = (props: TheoryAnswerProps) => {
  const educationContentType: EducationContentTypes = "answer";
  const account = useRecoilValue(userStateAccount);
  const chapter = useRecoilValue(theoryChapterState);
  const { assessment } = useAssessment({ key: chapter?.activityKey!, type: educationContentType });
  const theme = useTheme();
  const xapiKey: XapiKey = {
    userId: account!.actorId,
    contentId: chapter!.activityKey!.contentId,
    versionId: chapter!.activityKey!.versionId!,
    stateId: useRecoilValue(educationContentTypeState).get(educationContentType)!,
  };
  const { isMobile } = useMediaQueries();

  if (!account) {
    console.log("No active account");
    return null;
  }

  if (assessment === undefined) return null;

  if (assessment === null) return <NoContent heading={chapter?.title} />;

  // Start the test
  return (
    <Box mx={isMobile ? theme.spacing(0) : theme.spacing(-2)}>
      <Test label={TestLabel.Answer} assessment={assessment} xapiKey={xapiKey} userId={account.actorId} isAnswer />
    </Box>
  );
};
