import { Icon, iconCircleXmark } from "theme/icons";
import { alpha, createTheme, Direction, PaletteOptions, SimplePaletteColorOptions } from "@mui/material";
import { ShapeOptions } from "@mui/system";
import { common } from "@mui/material/colors";
import { TypographyOptions, TypographyStyleOptions } from "@mui/material/styles/createTypography";
import shadows from "@mui/material/styles/shadows";
import { fontFamily, optimaColors, fontSize } from "./optima";

export const desktopWidth420 = "420px";
export const desktopWidth640 = "640px";
export const defaultBorderRadius = 8;
export const defaultBorderColor = alpha(common.black, 0.12);

const typography: TypographyOptions = {
  fontFamily: fontFamily,
  // fontSize: (14 / 16) * 18, // Defaults to 18px
  h1: {
    fontSize: fontSize.h1,
    lineHeight: fontSize.h1,
    fontWeight: 400,
  },
  h2: {
    fontSize: fontSize.h2,
    lineHeight: fontSize.h2,
    fontWeight: 700,
    textTransform: "uppercase",
    letterSpacing: "0.5px",
  },
  h3: {
    fontSize: fontSize.h3,
    fontWeight: 500,
  },
  h4: {
    fontSize: fontSize.h4,
    fontWeight: 700,
    letterSpacing: "0.1px",
  },
  body1: {
    fontSize: fontSize.body1,
  },
  body2: {
    fontSize: fontSize.body2,
    lineHeight: 1.225,
  },
  bodyStrong: {
    fontSize: fontSize.bodyStrong,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  subtitle1: {
    fontSize: fontSize.subtitle1,
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: "0.5px",
  },
  subtitle2: {
    fontSize: fontSize.subtitle2,
    fontWeight: 500,
    lineHeight: "17px",
    letterSpacing: "0.5px",
  },
  caption: {
    fontSize: fontSize.caption,
    fontWeight: 400,
    lineHeight: 1.225,
    letterSpacing: "0.004em",
    // display: "inline-flex",
  },
  captionImage: {
    fontSize: fontSize.imageText,
    lineHeight: 1.0625,
    letterSpacing: "0.4px",
  },
  breadcrumbs: {
    fontSize: fontSize.breadcrumb,
    fontWeight: 500,
    // display: "inline-flex",
  },
  note: {
    fontSize: fontSize.note,
    fontStyle: "italic",
    lineHeight: 1.0625,
    letterSpacing: "0.25px",
  },

  button: {
    fontSize: fontSize.button,
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: 1.5,
    textTransform: "uppercase",
  },
};

const palette: PaletteOptions = {
  mode: "light",
  background: {
    default: "#F7F7F7",
    desktop: "#FDFDFD",
  },
  primary: {
    main: optimaColors.primary,
    light: optimaColors.primaryLight,
    dark: optimaColors.primaryDark,
    contrastText: common.white,
  },
  secondary: {
    main: optimaColors.secondary,
    light: optimaColors.secondaryLight,
    dark: optimaColors.secondaryDark,
    contrastText: common.white,
  },
  error: {
    main: optimaColors.error,
    light: optimaColors.errorLight,
    dark: optimaColors.errorDark,
    contrastText: common.white,
  },
  success: {
    main: optimaColors.success,
    light: optimaColors.successLight,
    dark: optimaColors.successDark,
    contrastText: common.white,
  },
  info: {
    main: optimaColors.info,
    light: optimaColors.infoLight,
    dark: optimaColors.infoDark,
    contrastText: common.black,
  },
  warning: {
    main: optimaColors.warning,
    light: optimaColors.warningLight,
    dark: optimaColors.warningDark,
  },
  text: {
    primary: "#393E40",
    secondary: "rgba(0,0,0,0.6)",
  },
};

const shape: ShapeOptions = {
  borderRadius: defaultBorderRadius,
};

export const theme = (direction: Direction) =>
  createTheme({
    direction: direction,
    header: {
      height: 14,
    },
    shape: shape,
    palette: palette,
    typography: typography,
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: "4.5rem",
            paddingLeft: "8px",
            paddingRight: "8px",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
          fontSize: fontSize.body2,
        },
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // Denna funkar konstigt nog inte under typography/button så måste ligga här
            borderRadius: defaultBorderRadius,
            padding: "0.75rem 1.5rem",
          },
          sizeSmall: {
            padding: "0.5rem 1rem",
          },
          contained: {
            boxShadow: "none",
            ":hover, :active": {
              boxShadow: "none",
            },
            "&.Mui-disabled": {
              color: "#FFF",
              backgroundColor: (palette.primary! as SimplePaletteColorOptions).light,
            },
          },
          outlined: {
            // backgroundColor: common.white,
            borderColor: defaultBorderColor,
          },
        },
      },
      MuiChip: {
        defaultProps: {
          deleteIcon: <Icon name={iconCircleXmark} />,
          // variant: "outlined",
        },
        styleOverrides: {
          root: {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: defaultBorderColor,
            fontSize: "12px",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            ".MuiChip-label": {
              padding: 0,
            },
            "&.MuiChip-colorSuccess": {
              backgroundColor: "#DFF9ED",
              color: palette.text!.primary,
            },
            "&.MuiChip-colorWarning": {
              backgroundColor: "#FDFACC",
              color: palette.text!.primary,
            },
            ".MuiSvgIcon-root": {
              fontSize: "20px",
              ":first-child": {
                marginRight: "0.25rem",
                marginLeft: "-0.2rem",
              },
              ":last-child": {
                marginLeft: "0.25rem",
                marginRight: "-0.2rem",
              },
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            // ".MuiSwitch-switchBase.Mui-checked": {
            //   color: optimaColors.primary06,
            //   "+.MuiSwitch-track": {
            //     backgroundColor: optimaColors.primary02,
            //   },
            // },
            ".MuiSwitch-track": {
              opacity: 0.14,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: fontSize.input,
            backgroundColor: common.white,
            "&:not(.MuiInputBase-multiline)": {
              height: "3rem",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: fontSize.input,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: alpha(common.black, 0.6),
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: fontSize.input,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            ".MuiPaper-root": {
              boxShadow: shadows[4],
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: fontSize.subtitle2,
            paddingTop: 0,
            paddingBottom: 0,
            color: common.white,
            lineHeight: 1.5,
            ".MuiAlert-icon": { color: common.white, opacity: 1, alignSelf: "center" },
            "&.MuiAlert-standardSuccess": { backgroundColor: (palette.success! as SimplePaletteColorOptions).main },
            "&.MuiAlert-standardError": { backgroundColor: (palette.error! as SimplePaletteColorOptions).main },
            "&.MuiAlert-standardWarning": {
              backgroundColor: (palette.warning! as SimplePaletteColorOptions).main,
              color: common.black,
              // border: `1px solid ${optimaColors.neutralWarning}`,
              // ".MuiSvgIcon-root": { color: optimaColors.neutralWarning },
            },
            "&.MuiAlert-standardInfo": { backgroundColor: (palette.info! as SimplePaletteColorOptions).main },
            ".MuiAlert-message": { width: "100%" },
          },
        },
      },
      MuiBottomNavigation: {
        defaultProps: {
          sx: {
            height: "70px",
            backgroundColor: (palette.primary! as SimplePaletteColorOptions).main,
          },
        },
      },
      MuiBottomNavigationAction: {
        defaultProps: {
          sx: {
            color: alpha("#FFFFFF", 0.74),
            minWidth: "auto",
            padding: "0 5px",
            ".MuiBottomNavigationAction-label": {
              fontSize: "12px",
              "&.Mui-selected": {
                fontSize: "12px",
              },
            },
            "&.Mui-selected": {
              color: common.white,
              fontWeight: 600,
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: defaultBorderRadius,
            backgroundColor: common.white,
            boxShadow: shadows[0],
            border: `1px solid ${defaultBorderColor}`,
            // boxShadow:
            //   "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);",
            // ".MuiCardActionArea-root:hover": {
            //   backgroundColor: optimaColors.primary01,
            // },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            ".MuiMenu-paper": {
              boxShadow: shadows[4],
              borderRadius: defaultBorderRadius,
              ".MuiMenu-list": {
                padding: 0,
                ".MuiMenuItem-root:hover": {
                  backgroundColor: alpha((palette.primary! as SimplePaletteColorOptions).main, 0.12),
                },
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: fontSize.body2,
            padding: "1rem",
            borderBottom: `1px solid ${defaultBorderColor}`,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            overflow: "hidden",
            borderRadius: defaultBorderRadius,
            ".MuiListItemButton-root .MuiListItemIcon-root": {
              color: defaultBorderColor,
            },
            ".MuiListItemButton-root.Mui-selected .MuiListItemIcon-root": {
              color: (palette.success! as SimplePaletteColorOptions).main,
            },
            h6: {
              lineHeight: "1.25",
            },
            ".MuiListItemSecondaryAction-disableGutters button": {
              marginRight: "10px",
            },
            ".MuiLinearProgress-root": {
              marginBottom: "-0.5rem",
            },
          },
        },
      },
      MuiDialog: {
        // MUI Bugfix: Prevent event bubbling from dialog
        defaultProps: {
          PaperProps: { onClick: (e: any) => e.stopPropagation() },
          onBackdropClick: (e) => e.stopPropagation(),
        },
        styleOverrides: {
          // Alert message in dialog
          root: {
            ".MuiDialog-container .MuiAlert-root.MuiPaper-root": {
              borderRadius: "4px",
              position: "relative",
              boxShadow: "none",
              marginBottom: "1.5rem",
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            ".MuiListItemButton-root:hover": {
              backgroundColor: (palette.primary! as SimplePaletteColorOptions).light01, //optimaColors.primary01,
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            // Topnav tabs
            ".MuiTabs-root": {
              ".MuiTab-root": {
                letterSpacing: "0.045em",
                fontWeight: "400",
                padding: "12px 24px",
                textAlign: "center",
                color: common.white,

                "&.Mui-selected": {
                  fontWeight: 600,
                },
                label: {
                  paddingLeft: "2px",
                  cursor: "pointer",
                },
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            // ".MuiTabs-indicator": { height: "1px" },
            ".MuiTab-root": {
              color: (palette.primary! as SimplePaletteColorOptions).light03, // optimaColors.primary03,
              minHeight: "auto",
              padding: "6px 24px",
              ".MuiSvgIcon-root": { marginRight: "0.5rem" },
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            margin: "1.5rem 0 2rem 0",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: defaultBorderRadius,
            boxShadow: shadows[0],
            border: `1px solid ${defaultBorderColor}`,
            "&::before": {
              display: "none",
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            "&.MuiAvatar-rounded": {
              borderRadius: "16px",
            },
          },
        },
      },
    },
  });

declare module "@mui/material/styles" {
  interface Theme {
    header: {
      height: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    header?: {
      height?: number;
    };
  }

  interface TypographyVariants {
    bodyStrong: TypographyStyleOptions;
    breadcrumbs: TypographyStyleOptions;
    note: TypographyStyleOptions;
    captionImage: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyStrong?: TypographyStyleOptions;
    breadcrumbs?: TypographyStyleOptions;
    note?: TypographyStyleOptions;
    captionImage?: TypographyStyleOptions;
  }

  interface PaletteColor {
    light01?: string;
    light02?: string;
    light03?: string;
    light04?: string;
    dark06?: string;
    dark07?: string;
    dark08?: string;
    dark09?: string;
  }

  interface SimplePaletteColorOptions {
    light01?: string;
    light02?: string;
    light03?: string;
    light04?: string;
    dark06?: string;
    dark07?: string;
    dark08?: string;
    dark09?: string;
  }

  interface TypeBackground {
    desktop?: string;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyStrong: true;
    breadcrumbs: true;
    note: true;
    captionImage: true;
  }
}
