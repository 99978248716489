import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { optimaColors } from "theme/optima";

const ListHeader = (props: PropsWithChildren) => {
  return (
    <Typography variant="captionImage" fontWeight={700} textTransform="uppercase" color={optimaColors.grey01}>
      {props.children}
    </Typography>
  );
};

export default ListHeader;
