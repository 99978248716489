import { Box, IconButton, Stack, Typography } from "@mui/material";
import Status from "./Status";
import { AddComment } from "./Comment/Comment";
import { LearningObjectiveType, EducationCardActivity } from "types/educationCard";
import ItemContainer from "./ItemContainer";
import { Icon, iconExternalLink } from "theme/icons";
import useRoles from "hooks/useRoles";
import Students from "./Students";
import FormSwitch from "utils/helpers/FormSwitch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { educationCardPropsState } from "state/educationCardState";
import { theoryState } from "state/theoryState";
import { theoryDialogState } from "state/recoilAtoms";
import { selectedActivityKeyState } from "state/learningStructureState";
import ButtonPdf from "./Pdf";

const LearningObjective = ({
  item,
  type,
  onApprove,
  onApproveAll,
}: {
  item: EducationCardActivity;
  type: LearningObjectiveType;
  onApprove(item: EducationCardActivity, type: LearningObjectiveType, checked: boolean): Promise<void>;
  onApproveAll?: (checked: boolean, userId?: string) => Promise<void>;
}) => {
  const { isEducator } = useRoles();
  const state = useRecoilValue(educationCardPropsState);
  const educationActivities = useRecoilValue(theoryState);
  const setSelectedActivity = useSetRecoilState(selectedActivityKeyState);
  const openDialog = useSetRecoilState(theoryDialogState);

  // TODO: PDF link should be dynamic and set on item
  const itemPdf = process.env.PUBLIC_URL + "/pdf/Forarbevisprotokoll.pdf";

  async function handleApprove(checked: boolean) {
    await onApprove(item, type, checked);
  }

  const handleNavigateToChapter = () => {
    if (!educationActivities) throw new Error("No education activities found");

    setSelectedActivity(item.referenceKey);
    openDialog(true);
  };

  return (
    <ItemContainer>
      <Box flexGrow={1} display="flex" alignItems="center">
        <Typography variant="subtitle2">{item.title}</Typography>

        {isEducator && type === "theoretical" && (
          <IconButton color="info" onClick={handleNavigateToChapter}>
            <Icon name={iconExternalLink} size="small" />
          </IconButton>
        )}
        {isEducator && type === "practical" && <ButtonPdf pdf={itemPdf} iconButton />}
      </Box>
      <Stack spacing={1} direction="row" alignItems="center">
        {type === "theoretical" && <Status status={item.status} />}
        {isEducator && <FormSwitch checked={item.approved} onChange={handleApprove} noLabel />}
        {isEducator && state?.type === "education" && (
          <Students item={item} onApprove={onApprove} onApproveAll={onApproveAll} />
        )}
        {state?.type !== "user" && <AddComment comments={item.comments} title={item.title} />}
      </Stack>
    </ItemContainer>
  );
};

export default LearningObjective;
