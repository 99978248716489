import { Stack, Typography } from "@mui/material";
import { Progress } from "layout/Components/ProgressCard";
import List from "./List";
import { EducationCardType, useEducationCardSettings } from "hooks/useEducationCardSettings";
import { useParams } from "react-router-dom";
import { EducationCardActivity, EducationCardProgress } from "types/educationCard";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useAppIntl } from "services/useAppIntl";
import { educationsSelector, groupSelector, studentSelector } from "state/educatorState";
import { headerCrumbState } from "state/recoilAtoms";
import ItemTestTheoretical from "./ItemTestTheoretical";
import ItemTestPractical from "./ItemTestPractical";
import FormSwitch from "utils/helpers/FormSwitch";
import ItemTemp from "./ItemLesson-TEMP";
import { AddComment } from "./Comment/Comment";

// TEMPORARY MOCK DATA
const theoreticalTest: EducationCardActivity = {
  id: "1",
  referenceKey: { contentId: "1", versionId: "1" },
  parentKey: { contentId: "1", versionId: "1" },
  rootKey: { contentId: "1", versionId: "1" },
  title: "Teoretiskt prov",
  approved: false,
  status: "None",
  theoryItems: [
    {
      id: "1",
      referenceKey: { contentId: "1", versionId: "1" },
      parentKey: { contentId: "1", versionId: "1" },
      rootKey: { contentId: "1", versionId: "1" },
      title: "20 maj 2023",
      approved: false,
      status: "None",
    },

    {
      id: "2",
      referenceKey: { contentId: "1", versionId: "1" },
      parentKey: { contentId: "1", versionId: "1" },
      rootKey: { contentId: "1", versionId: "1" },
      title: "3 sep 2023",
      approved: false,
      status: "None",
    },
  ],
};

const practicalTest: EducationCardActivity = {
  id: "1",
  referenceKey: { contentId: "1", versionId: "1" },
  parentKey: { contentId: "1", versionId: "1" },
  rootKey: { contentId: "1", versionId: "1" },
  title: "Praktiskt prov",
  approved: false,
  status: "Success",
  practiceItems: [
    {
      id: "1",
      referenceKey: { contentId: "1", versionId: "1" },
      parentKey: { contentId: "1", versionId: "1" },
      rootKey: { contentId: "1", versionId: "1" },
      title: "Uttag fordon",
      approved: false,
      status: "Success",
    },
  ],
};

const lesson: EducationCardActivity = {
  id: "1",
  referenceKey: { contentId: "1", versionId: "1" },
  parentKey: { contentId: "1", versionId: "1" },
  rootKey: { contentId: "1", versionId: "1" },
  title: "Uttag fordon",
  approved: false,
  status: "None",
  practiceItems: [
    {
      id: "1",
      referenceKey: { contentId: "1", versionId: "1" },
      parentKey: { contentId: "1", versionId: "1" },
      rootKey: { contentId: "1", versionId: "1" },
      title: "Starta och stoppa motorn",
      approved: false,
      status: "None",
    },

    {
      id: "2",
      referenceKey: { contentId: "1", versionId: "1" },
      parentKey: { contentId: "1", versionId: "1" },
      rootKey: { contentId: "1", versionId: "1" },
      title: "Använda fordonets manöverorgan",
      approved: false,
      status: "None",
    },

    {
      id: "3",
      referenceKey: { contentId: "1", versionId: "1" },
      parentKey: { contentId: "1", versionId: "1" },
      rootKey: { contentId: "1", versionId: "1" },
      title: "Avläsa fordonets kontrollorgan",
      approved: false,
      status: "None",
    },

    {
      id: "4",
      referenceKey: { contentId: "1", versionId: "1" },
      parentKey: { contentId: "1", versionId: "1" },
      rootKey: { contentId: "1", versionId: "1" },
      title: "Hitta fordonets tillbehör och utrustning",
      approved: false,
      status: "None",
    },
  ],
};
// TEMPORARY MOCK DATA

// Set the breadcrumb for the EducatorHeader
const useSetCrumb = () => {
  const { groupId, studentId, educationId } = useParams();
  const intl = useAppIntl();
  const group = useRecoilValue(groupSelector(groupId));
  const student = useRecoilValue(studentSelector({ eventId: groupId ?? "", actorId: studentId ?? "" }));
  const education = useRecoilValue(educationsSelector(groupId))?.filter((e) => e.educationId === educationId)[0];
  const setCrumb = useSetRecoilState(headerCrumbState);

  useEffect(() => {
    if (!group?.name) return;
    if (!education?.name) return;

    let topTitle = `${intl.formatMessage({ id: "common.title.educator" })}/${group.name}`;
    if (student) {
      const name = `${student.extensions?.firstName} ${student.extensions?.lastName}`;
      topTitle = `${topTitle}/${name}`;
    }

    setCrumb({ title: education.name, topTitle });
  }, [group?.name, intl, setCrumb, student, education?.name]);
};

type EducationCardProps = {
  type: EducationCardType;
};
const EducationCard = ({ type }: EducationCardProps) => {
  const { groupId, educationId, studentId } = useParams();
  const currentIdentifier = `${groupId}-${educationId}-${studentId}`;
  const settings = useEducationCardSettings({ type, id: groupId, educationId, studentId });
  const [progress, setProgress] = useState<EducationCardProgress>({
    practical: 0,
    theoretical: 0,
    theoreticalByInstructor: 0,
  });
  useSetCrumb();

  // TODO: Fixa currentIdentifier. Blir undefined när man är inloggad som elev (dvs då använder vi inga params)

  if (!settings) return null;
  if (groupId !== undefined && groupId !== settings.eventId) return null; // Vad gör denna?
  if (type !== settings.type) return null;
  if (currentIdentifier !== settings.identifier) return null;

  console.log("Educationcard props", "name", settings.name, "key", settings.referenceKey, "userIds", settings.userIds);

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h1">{settings.name}</Typography>

        {(settings.type === "education" || settings.type === "student") && (
          <Stack spacing={1} direction="row" alignSelf={"end"}>
            <ApproveEducationCard />
            <AddComment comments={undefined} title={"utbildningen"} />
          </Stack>
        )}
        <Progress progress={progress} isUser={settings.type === "user"} />
        {/* <TestResults /> */}
      </Stack>
      <List
        eventId={settings.eventId}
        referenceKey={settings.referenceKey}
        userIds={settings.userIds}
        educationCardIdentifier={settings.identifier}
        setProgress={setProgress}
      />

      <Stack spacing={1.5} mt={1.5}>
        {/* SPECIALARE */}
        <ItemTemp item={lesson} />

        <ItemTestTheoretical item={theoreticalTest} />
        <ItemTestPractical item={practicalTest} />
      </Stack>
    </>
  );
};

export default EducationCard;

const ApproveEducationCard = () => {
  const [approved, setApproved] = useState(false);
  return (
    <Stack alignItems="start">
      <FormSwitch checked={approved} label="Godkänd" onChange={() => setApproved(!approved)} />
    </Stack>
  );
};
