export const fontFamily = "Forsvarsmakten Sans, proxima-nova, sans-serif";

export const optimaColors = {
  primary: "#5E6971",
  primaryLight: "#AAB3B6",
  primaryDark: "#3F474C",

  secondary: "#5E6971",
  secondaryLight: "#AAB3B6",
  secondaryDark: "#424946",

  error: "#FF675D",
  errorLight: "#DA584F",
  errorDark: "#C74A41",

  success: "#2BDB8C",
  successLight: "rgba(38, 145, 97, 0.15)",
  successDark: "#269161",

  info: "#005EAB",
  infoLight: "#D9E7F2",
  infoDark: "#003E71",

  warning: "#F5E500",
  warningLight: "#FEFBD9",
  warningDark: "#C6A800",

  grey01: "#716F6F",
};

export const fontSize = {
  h1: "32px",
  h2: "20px",
  h3: "20px",
  h4: "18px",
  body1: "18px",
  body2: "16px",
  bodyStrong: "20px",
  input: "14px",
  subtitle1: "16px",
  subtitle2: "14px",
  caption: "12px",
  button: "16px",
  breadcrumb: "10px",
  note: "14px",
  imageText: "12px",
};
