import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { LoadingStatus } from "types/request";
import { Icon, iconChevronDown } from "theme/icons";
import { defaultBorderColor } from "theme/theme";

type AccordionEducationCardProps = {
  id: string;
  summary: NonNullable<ReactNode>;
  details: NonNullable<ReactNode>;
  status: LoadingStatus;
};
const AccordionEducationCard = ({ id, summary, details, status }: AccordionEducationCardProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const target = event.target as HTMLElement;

    // Prevent expanding when clicking on buttons or inputs
    if (target.tagName === "INPUT" || target.tagName === "BUTTON") return;
    if (target.tagName === "svg" || target.tagName === "path") {
      if (target.parentElement?.tagName === "BUTTON") return;
      if (target.parentElement?.tagName === "svg") {
        if (target.parentElement?.parentElement?.tagName === "BUTTON") return;
        if (target.parentElement?.parentElement?.parentElement?.tagName === "BUTTON") return;
      }
    }

    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      disableGutters
      disabled={status === "pending"}
      onChange={handleExpand(`panel-${id}`)}
      expanded={expanded === `panel-${id}`}
      sx={{
        ".MuiAccordionSummary-root": { flexDirection: "row-reverse" },
        ".MuiAccordionSummary-expandIconWrapper": { mr: 1 },
      }} // Switch expand icon to the right
    >
      <AccordionSummary
        expandIcon={<Icon name={iconChevronDown} color="info" />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: `1px solid ${defaultBorderColor}`, paddingTop: 1.5 }}>
        {details}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionEducationCard;
