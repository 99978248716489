import { ReactNode, FC } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { HeaderNavigation } from "utils/helpers/HeaderNavigation";
import { useRecoilValue } from "recoil";
import { headerCrumbState } from "state/recoilAtoms";

export interface IHeaderProps {
  children?: ReactNode;
  callback?: () => void;
  tabs?: ReactNode;
}

export const Header: FC<IHeaderProps> = (props) => {
  const { title, topTitle } = useRecoilValue(headerCrumbState);

  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar>
        <HeaderNavigation iconSize={"large"} topTitle={topTitle} title={title} onNavigate={props.callback} />
      </Toolbar>
      {props.tabs}
    </AppBar>
  );
};
