import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { educatorsSelector } from "state/educatorState";
import { optimaColors } from "theme/optima";
import { LearningEventEducator } from "types/learningEvent";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

const GroupEducators = () => {
  const { groupId } = useParams();
  const educators = useRecoilValue(educatorsSelector(groupId));

  const responsibleEducators = educators.filter((e) => e.extensions.isResponsible);
  const otherEducators = educators.filter((e) => !e.extensions.isResponsible);

  return (
    <>
      <EducatorCard educators={responsibleEducators} isResponsible />
      <EducatorCard educators={otherEducators} />
    </>
  );
};

export default GroupEducators;

const EducatorCard = ({
  educators,
  isResponsible,
}: {
  isResponsible?: boolean;
  educators: LearningEventEducator[];
}) => {
  if (educators.length === 0)
    return (
      <Card sx={{ border: "none" }}>
        <CardContent sx={{ px: 3 }}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <FormattedTypography
                id={isResponsible ? "educator.label.responsible" : "educator.label"}
                variant="subtitle1"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={optimaColors.grey01}>
                Ej angiven
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );

  return (
    <Card sx={{ border: "none" }}>
      <CardContent sx={{ px: 3 }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <FormattedTypography
              id={isResponsible ? "educator.label.responsible" : "educator.label"}
              variant="subtitle1"
            />
          </Grid>
          <Grid item xs={4}>
            <FormattedTypography
              id="common.label.identity-number"
              variant="subtitle2"
              display="inline-block"
              color={optimaColors.grey01}
            />
          </Grid>
          <Grid item xs={4}>
            <FormattedTypography
              id="common.label.name"
              variant="subtitle2"
              display="inline-block"
              color={optimaColors.grey01}
            />
          </Grid>
          <Grid item xs={4}>
            <FormattedTypography
              id="common.email"
              variant="subtitle2"
              display="inline-block"
              color={optimaColors.grey01}
            />
          </Grid>
          {educators?.map((educator) => (
            <Educator key={educator.id} educator={educator} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const Educator = ({ educator }: { educator: LearningEventEducator }) => {
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{educator.extensions.identityNumber}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">
          {educator.extensions?.firstName} {educator.extensions?.lastName}{" "}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{educator.extensions.email}</Typography>
      </Grid>
    </>
  );
};
