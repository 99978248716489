import "theme/fonts/font";
import "theme/fonts/font.css";
import { ReactNode, useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "theme/theme";
import { DefaultRoutes } from "routes";
import { IntlProvider } from "react-intl";
import { messages, SupportedLanguages } from "services/localizationService";
import { useAppIntl } from "services/useAppIntl";
import { useRecoilState } from "recoil";
import ReactGA from "react-ga4";
import { SnackbarMessage } from "utils/helpers/SnackbarMessage";
import { passwordChangedSuccess } from "state/userState";
import { AppContextProvider, useAppContext } from "context/AppContext";
import { FeatureFlagProvider, useFetchFeatureFlag } from "@strmediaochitab/optima-react-components";
import { fetchFeatureFlag, fetchFeatureFlags } from "services/featureFlagService";
import OfflineInfo from "layout/Components/OfflineInfo";
import { Maintenance } from "layout/maintenance";
import { useGlobalSnackbarMessage } from "hooks/useSnackbarMessage";

const App: React.FC = () => {
  ReactGA.initialize("G-475R5GPG2H");

  return (
    <ThemeProvider theme={theme("ltr")}>
      <AppContextProvider>
        <AppLanguage>
          <FeatureFlagProvider fetchFeatureFlag={fetchFeatureFlag} fetchFeatureFlags={fetchFeatureFlags}>
            <AppLayout />
            <OfflineInfo />
          </FeatureFlagProvider>
        </AppLanguage>
      </AppContextProvider>
    </ThemeProvider>
  );
};

export default App;

const AppLanguage = ({ children }: { children: ReactNode }) => {
  const { activeLanguage } = useAppContext();
  let locale = activeLanguage;

  // Split locale if it contains a colon (for special terms)
  if (locale.includes(":")) {
    const [language, term] = locale.split(":");
    console.log("language", language, "term", term);
    locale = language as SupportedLanguages;
  }

  return (
    <IntlProvider defaultLocale="sv" locale={locale} messages={messages[activeLanguage]}>
      {children}
    </IntlProvider>
  );
};

const AppLayout = () => {
  const intl = useAppIntl();
  const { snackbarMessage } = useGlobalSnackbarMessage();
  const [passwordChanged, setPasswordChanged] = useRecoilState(passwordChangedSuccess);
  const setPasswordChangedMemo = useMemo(() => {
    return () => setPasswordChanged(false);
  }, [setPasswordChanged]);

  const maintenance = useFetchFeatureFlag("Maintenance");

  if (maintenance)
    return (
      <>
        <CssBaseline />
        <Maintenance />
      </>
    );

  return (
    <>
      <CssBaseline />
      <DefaultRoutes />
      <SnackbarMessage
        show={!!passwordChanged}
        type="success"
        message={intl.formatMessage({ id: "common.save.message" })}
        onClose={setPasswordChangedMemo}
      />
      {snackbarMessage}
    </>
  );
};
