import React, { useEffect } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { iconBookmark, iconClock, iconShuffle } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import { WelcomeMessage } from "utils/helpers/WelcomeMessage";
import { Title } from "utils/helpers/TitleDivider";
import { testId } from "tests/testIdStrings";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useRecoilValue } from "recoil";
import { educationState } from "state/educationState";
import { useNavigate } from "react-router-dom";
import ContinueTheory from "./Components/ContinueTheory";
import ProgressCard from "./Components/ProgressCard";
import NavigationItemStudyHelp from "./Components/NavigationItemStudyHelp";

export const Home: React.FC = () => {
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const navigate = useNavigate();
  const useBookmarks = useFeatureFlag("Bookmarks");
  const currentEducationIdentifier = useRecoilValue(educationState)?.educationId;

  useEffect(() => {
    if (!currentEducationIdentifier) navigate(routes.education.path);
  }, [currentEducationIdentifier, navigate, routes.education.path]);

  useEffect(() => {
    if (!localStorage.getItem("showConsentWarning")) localStorage.setItem("showConsentWarning", "true");
  });

  if (!currentEducationIdentifier) return null;

  return (
    <Box sx={{ position: "relative" }}>
      <WelcomeMessage />

      <Title title={intl.formatMessage({ id: "common.title.progress" })} variant="h2" />
      <Box mb={5}>
        <ProgressCard />
      </Box>

      <Title title={intl.formatMessage({ id: "common.title.theory" })} variant="h2" />
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} sm={6}>
          <ContinueTheory />
        </Grid>
        <Grid item xs={12} sm={6}>
          {useBookmarks && (
            <NavigationItem
              href={routes.bookmarks.path}
              text={intl.formatMessage({ id: "more.title.bookmarksandnotes" })}
              icon={iconBookmark}
              dataTestIdString={testId.listItem.home.roadsigns}
              height={"100%"}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <NavigationItemStudyHelp />
        </Grid>
      </Grid>

      <Title title={intl.formatMessage({ id: "common.title.test" })} variant="h2" />
      <Stack spacing={2} direction="row">
        <NavigationItem
          text={intl.formatMessage({ id: "test.type.study.quick" })}
          subText={intl.formatMessage({ id: "test.type.study.quick.subtext" }, { values: { value: 10 } })}
          subTextVariant="caption"
          icon={iconClock}
          dataTestIdString={testId.listItem.home.testTodaysQuestion}
          href={routes.test.path + "/quick/study"}
        />
        <NavigationItem
          text={intl.formatMessage({ id: "common.title.test.quick2" })}
          subText={intl.formatMessage({ id: "test.type.study.quick2.subtext" }, { values: { value: 10 } })}
          subTextVariant="caption"
          icon={iconShuffle}
          href={"quick/study2"}
          dataTestIdString={testId.listItem.test.type.quick}
        />{" "}
      </Stack>
    </Box>
  );
};
