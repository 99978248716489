import { useMemo } from "react";
import { useTheme, Box } from "@mui/material";
import { Icon, iconChevronDown } from "theme/icons";
import { useNavigate } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useAppIntl } from "services/useAppIntl";
import { EducationStateEnum, UserEducation } from "types/education";
import { DropdownMenu } from "utils/helpers/dropdown/DropdownMenu";
import { ButtonLabel, EducationMenuItems, IEducationMenuItem } from "utils/helpers/dropdown/MenuItemsEducation";
import { useSnackbarMessage } from "hooks/useSnackbarMessage";
import { useEducation } from "hooks/useEducation";
import useMediaQueries from "hooks/useMediaQueries";

const HeaderEducationMenu: React.FC = () => {
  const theme = useTheme();
  const { isMobile } = useMediaQueries();
  const intl = useAppIntl();
  const routeConfig = useRouteConfiguration();
  const navigate = useNavigate();
  const { setSnackbarValues, snackbarMessage } = useSnackbarMessage();
  const { userEducations, education, setEducation } = useEducation();
  const currentEducationIdentifier = education?.educationId;

  const setCurrentActiveEducation = (education: UserEducation) => {
    setEducation(education);
    navigate(`${routeConfig.home.path}`);
  };

  const handleCallBack = (id?: string) => {
    const selectedEducation = userEducations?.find((x) => x.educationId === id);
    if (!selectedEducation) return;

    setEducation(selectedEducation);
    setSnackbarValues({
      message: intl.formatMessage(
        { id: "education.change.current.acknowledge.message" },
        { values: { name: selectedEducation.name } }
      ),
      type: "success",
      // Custom position of snackbar in start page
      customSx: isMobile
        ? { right: "16px", left: "16px", bottom: "95px" }
        : { top: "calc(100vh - 150px)", position: "relative" },
    });
  };

  const menuItems = useMemo(() => {
    const getTitle = (isActive: boolean, education: UserEducation): string => {
      // const getTranslatedStatus = () => {
      //   const intlId = `education.${education.educationState.toLocaleLowerCase()}` as IntlMessageKeys;
      //   return intl.formatMessage({ id: intlId }, { casingType: CasingType.lowerCase });
      // };

      return isActive ? education.name : `${education.name}`;
    };

    return (
      userEducations?.map((education) => {
        const isActive = education.educationState === EducationStateEnum.Active;
        const item: IEducationMenuItem = {
          id: education.educationId,
          selected: education.educationId === currentEducationIdentifier,
          title: getTitle(isActive, education),
          customAction: () => setCurrentActiveEducation(education),
          customStyle:
            education.educationState === EducationStateEnum.Paused ? { iconColor: theme.palette.grey[500] } : undefined,
        };
        return item;
      }) ?? []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEducations, currentEducationIdentifier]);

  const styleSelect = {
    color: "white",
    textTransform: "none",
    width: "220px",
    maxWidth: "220px",
  };

  let selectedEducationItem = menuItems.find((x) => x.id === currentEducationIdentifier);

  return (
    <>
      <DropdownMenu
        id="ddEducationsHeader"
        label={<ButtonLabel item={selectedEducationItem!} />}
        endIcon={<Icon sx={{ marginLeft: -2 }} name={iconChevronDown} />}
        sxButton={styleSelect}
        variant="text"
        onSetLabel={(x) => x}
        onClick={(item) => handleCallBack(item.id)}
      >
        <EducationMenuItems items={menuItems} />
      </DropdownMenu>
      <Box
        sx={
          isMobile
            ? null
            : {
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }
        }
      >
        {snackbarMessage}
      </Box>
    </>
  );
};
export default HeaderEducationMenu;
