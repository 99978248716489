import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Stack } from "@mui/material";
import { Title } from "utils/helpers/TitleDivider";
import Educations from "./Educations";
import Educators from "./Educators";
import { groupSelector } from "state/educatorState";
import { headerCrumbState } from "state/recoilAtoms";
import { useAppIntl } from "services/useAppIntl";
import useBackNavigation from "hooks/useBackNavigation";

// Set the breadcrumb for the EducatorHeader
export const useSetCrumb = () => {
  const { groupId } = useParams();
  const intl = useAppIntl();
  const group = useRecoilValue(groupSelector(groupId));
  const setCrumb = useSetRecoilState(headerCrumbState);

  useEffect(() => {
    setCrumb({ title: group?.name ?? "", topTitle: intl.formatMessage({ id: "common.title.educator" }) });
  }, [group?.name, intl, setCrumb]);
};

const Group = () => {
  const { groupId } = useParams();
  const group = useRecoilValue(groupSelector(groupId));
  useBackNavigation("/");
  useSetCrumb();

  return (
    <>
      <Title title={group?.name} variant="h1" />

      <Stack spacing={3}>
        {/* <Card sx={{ border: "none" }}>
          <CardContent sx={{ px: 3 }}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <FormattedTypography id="common.title.educator.information" variant="subtitle1" />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" color={optimaColors.grey01}>
                  Titel
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color={optimaColors.grey01}>
                  ID nummer
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2">{group?.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">{group?.id}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card> */}

        <Educators />
        <Educations />
      </Stack>
    </>
  );
};

export default Group;
