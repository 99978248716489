import { ReactNode, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useAccount } from "@azure/msal-react";
import {
  ItemQuestion,
  LearningObject,
  LRSProvider,
  LRSProviderState,
  LRSRecord,
  Question,
} from "@strmediaochitab/optima-component-library";
import { useAppContext } from "context/AppContext";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { QuestionNavigation } from "utils/helpers/theory/QuestionNavigation";
import useRoutePath from "hooks/useRoutePath";
import { buttonContainerBottomWrapper } from "theme/styles";
import { ResultOverview } from "services/reportingService";
import { useRecoilValue } from "recoil";
import { studyHelpState } from "state/progresState";
import { useTopNavigation } from "layout";

const initialState: LRSRecord = new Map<string, LRSProviderState>();

// TEMP function until we have a proper mapping in state
function mapLrsState(state: ResultOverview, userId: string): LRSRecord {
  const stringArray = JSON.parse(state.result!.response!).map((value: any) => value.toString());
  state.result!.response = JSON.stringify(stringArray);

  if (!state.referenceKey) throw new Error("referenceKey is undefined");

  // TODO: Fix mapping of lrsState
  const lrsState: any = {
    ...state,
    id: state.referenceKey.versionId,
    user: { id: userId },
    result: {
      ...state.result,
      completion: true,
    },
  };

  return new Map<string, LRSProviderState>().set(state.referenceKey.versionId, lrsState);
}

const Page = () => {
  const { questionId } = useParams();
  const account = useAccount();
  const location = useLocation();
  const navigate = useNavigate();
  const { setTopNavigate } = useTopNavigation();
  const [lrsState, setLrsState] = useState<LRSRecord>(initialState);
  const { results } = location.state as { results: ResultOverview[] };
  const result = results.find((result) => result.question!.id.contentId === questionId);

  if (!result) throw new Error("Result not found");
  if (!result.question) throw new Error("Question not found");
  if (!account) throw new Error("Account not found");

  useEffect(() => {
    setTopNavigate({
      function: () => navigate("../..", { relative: "path" }),
    });

    return () => {
      setTopNavigate({ function: undefined });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const state = mapLrsState(result, account.localAccountId);

    setLrsState(state);
  }, [result, account.localAccountId]);

  return (
    <Stack spacing={4}>
      <QuestionProvider lrsState={lrsState} question={result.question} />
      <StudyHelpProvider />

      <NavigationButtons results={results} />
    </Stack>
  );
};

export default Page;

const QuestionProvider = ({
  lrsState,
  question,
  children,
}: {
  lrsState: LRSRecord;
  question: ItemQuestion;
  children?: ReactNode;
}) => {
  const account = useAccount();
  const { activeLanguage } = useAppContext();

  return (
    <LRSProvider
      user={account!.localAccountId}
      language={activeLanguage}
      callbackSetQuestion={() => {}}
      state={{ questions: lrsState }}
    >
      <Question question={question} renderButton={false} />
      {children}
    </LRSProvider>
  );
};

const StudyHelpProvider = () => {
  const account = useAccount();
  const { activeLanguage } = useAppContext();
  const lrsState = initialState;

  // TEMP keys. should be fetched from somewhere...
  const keys = [
    {
      contentId: "71438724-2a5c-4515-a1d9-8823561c7893",
      versionId: "61d695e8-c71f-4c75-b594-ce2d6cede94a",
    },
    {
      contentId: "af2e8504-7e5c-430e-86b6-d821872752a9",
      versionId: "64088ce5-6c18-4a6d-8d7d-4cdac8980d13",
    },
  ];

  const activities = useRecoilValue(studyHelpState(keys));

  return (
    <LRSProvider
      user={account!.localAccountId}
      language={activeLanguage}
      callbackSetQuestion={() => {}}
      state={{ questions: lrsState }}
    >
      {activities.map((learningObject: any) => (
        <Box key={learningObject.contentId} className="optima-learning-object" data-type="learning-object">
          <LearningObject data={learningObject} headingLevel={1} onAction={() => {}} />
        </Box>
      ))}
    </LRSProvider>
  );
};

const NavigationButtons = ({ results }: { results: ResultOverview[] }) => {
  const currentPath = useRoutePath();
  const navigate = useNavigate();
  const { id, questionId } = useParams();

  const navigateToQuestion = (qId: string) => {
    navigate(currentPath.replace(":id", id!).replace(":questionId", qId!), {
      state: { question: results.find((result: any) => result.question.id.contentId === qId), results },
    });
  };

  const currentIndex = results.findIndex((result) => result.question!.id.contentId === questionId);
  const previousQuestion = currentIndex > 0 ? results[currentIndex - 1].question!.id.contentId : null;
  const nextQuestion = currentIndex < results.length - 1 ? results[currentIndex + 1].question!.id.contentId : null;

  return (
    <Box sx={buttonContainerBottomWrapper}>
      <QuestionNavigation nextId={nextQuestion} previousId={previousQuestion} navigate={navigateToQuestion} />
    </Box>
  );
};
