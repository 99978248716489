import { useState } from "react";
import { Icon, iconAward } from "theme/icons";
import { Stack, Typography } from "@mui/material";
import Status from "./Status";
import Comment from "./Comment/Comment";
import ItemContainer from "./ItemContainer";
import FormSwitch from "utils/helpers/FormSwitch";
import useRoles from "hooks/useRoles";
import { LoadingStatus } from "types/request";
import { useRecoilValue } from "recoil";
import { educationCardPropsState } from "state/educationCardState";
import LearningObjective from "./LearningObjective";
import { EducationCardActivity, LearningObjectiveType } from "types/educationCard";
import AccordionEducationCard from "layout/Components/AccordionEducationCard";
import Students from "./Students";
import ButtonPdf from "./Pdf";
import LearningObjectives from "./LearningObjectives";

const ItemTemp = ({ item }: { item: EducationCardActivity }) => {
  // const { item, upsertListItem } = useEducationCardListItem(id);
  const { isEducator } = useRoles();
  const [status, setStatus] = useState<LoadingStatus>("none");
  const state = useRecoilValue(educationCardPropsState);

  if (!state) throw new Error("No education card state found");

  // TODO: PDF link should be dynamic and set on item
  const itemPdf = process.env.PUBLIC_URL + "/pdf/PM-Uttag-fordon.pdf";

  // Approve a single learningObjective
  const handleApproveItem = async (item: EducationCardActivity, type: LearningObjectiveType, checked: boolean) => {
    if (!state) throw new Error("No education card state found");

    console.log("Approving", item.title);
    setStatus("pending");

    // const updatedItem = await setApproved(
    //   state.eventId,
    //   item.rootKey,
    //   item.parentKey,
    //   [item.referenceKey],
    //   state.userIds,
    //   type,
    //   checked,
    //   item.learningObjectives,
    //   state.identifier
    // );

    // TODO: handle errors...

    // upsertListItem(updatedItem);

    setStatus("none");
    console.log(item.title, "is", checked ? "approved" : "not approved");
  };

  return (
    <AccordionEducationCard
      id={item.id}
      status={status}
      summary={
        <ItemContainer>
          <Stack spacing={1} direction="row" alignItems="center">
            {/* <Icon name={iconAward} color="info" /> */}
            <Typography variant="subtitle2">{item.title}</Typography>
            {isEducator && <ButtonPdf pdf={itemPdf} />}
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            {isEducator ? (
              <>
                <Status status={item.status} />
                <FormSwitch label="Klar" checked={item.approved} />
                {state.type !== "education" && <Comment hasComment={false} noButton />}
                {state.type === "education" && <Students item={item} />}
              </>
            ) : (
              <>
                <Status status={item.status} />
                {state.type !== "education" && <Comment hasComment={false} noButton />}
              </>
            )}
          </Stack>
        </ItemContainer>
      }
      details={
        <Stack direction={"column"} spacing={4}>
          <LearningObjectives type="practical" items={item.practiceItems} tempProp={state.type}>
            {item.practiceItems?.map((subItem) => (
              <LearningObjective key={subItem.id} item={subItem} type={"practical"} onApprove={handleApproveItem} />
            ))}
          </LearningObjectives>
        </Stack>
      }
    />
  );
};

export default ItemTemp;
