import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Slide,
  useTheme,
  DialogProps,
  Theme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Icon, IconProp, iconXmark } from "theme/icons";
import { styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentBetween } from "theme/styles";
import { defaultBorderRadius, desktopWidth640 } from "theme/theme";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { IntlMessageKeys } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { DataTestType } from "tests/test.setup";
import { SxProps } from "@mui/system";
import { LoadingIndicator } from "./LoadingIndicator";
import useMediaQueries from "hooks/useMediaQueries";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styleDialog = (isMobile?: boolean, sx?: SxProps<Theme>): SxProps<Theme> => {
  return {
    ".MuiDialog-container .MuiPaper-root": {
      width: "100%",
      maxWidth: isMobile ? "none" : desktopWidth640,
      margin: "0",
      position: "absolute",
      bottom: isMobile ? "0" : "auto",
      borderRadius: isMobile ? `${defaultBorderRadius}px ${defaultBorderRadius}px 0 0` : `${defaultBorderRadius}px`,
      minHeight: "auto",
    },
    ".MuiDialogContent-root p:last-of-type": {
      paddingBottom: 0,
    },
    ...sx,
  };
};

const styleDialogPaddingX = { px: "1rem" };
const styleDialogPaddingY = { py: "1.5rem" };

interface IDialogHeaderProps extends DataTestType {
  title?: string | JSX.Element;
  divider?: boolean;
  close: () => void;
}
const DialogHeader = (props: IDialogHeaderProps) => {
  const theme = useTheme();

  return (
    <DialogTitle
      variant="h3"
      sx={[styleDialogPaddingX, { py: "0.75rem", mb: 0 }, props.divider ? { borderBottom: "1px solid #D9D9D9" } : null]}
      data-testid={`${testId.common.dialogHeader}${props.dataTestIdString ? "-" + props.dataTestIdString : ""}`}
    >
      <Box sx={[styleFlex, styleFlexJustifyContentBetween, styleFlexAlignItemsCenter]}>
        {props.title}
        <IconButton
          onClick={props.close}
          data-testid={`${props.dataTestIdString}-${testId.button.dialogMessage}-${testId.common.close}`}
          sx={{ marginRight: "-0.75rem" }}
        >
          <Icon name={iconXmark} fontSize={"32px"} htmlColor={theme.palette.primary.dark06} />
        </IconButton>
      </Box>
    </DialogTitle>
  );
};

interface IDialogMessageProps extends DialogProps, DataTestType {
  close: () => void;
  title: string;
  primaryButtonText: IntlMessageKeys;
  primaryButtonColor?: "inherit" | "primary" | "secondary" | "error" | "info" | "warning" | "success" | undefined;
  primaryButtonIcon?: IconProp;
  primaryButtonAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  primaryButtonLoader?: boolean;
  primaryButtonDisabled?: boolean;
  secondaryButtonText?: IntlMessageKeys;
  secondaryButtonAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  secondaryButtonColor?: "inherit" | "primary" | "secondary" | "error" | "info" | "warning" | "success" | undefined;
  secondaryButtonIcon?: IconProp;
  secondaryButtonVariant?: "text" | "contained" | "outlined" | undefined;
  noCancelButton?: boolean /** Cancel button is default. Set to true if you don't need it */;
}
export const DialogMessage = (props: IDialogMessageProps) => {
  const { isTablet } = useMediaQueries();

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.close}
      sx={styleDialog(isTablet, props.sx)}
      disableRestoreFocus // workaround to enable focus on input fields on dialog open
    >
      <DialogHeader close={props.close} title={props.title} dataTestIdString={props.dataTestIdString} />
      <Divider sx={{ margin: 0 }} />

      <DialogContent sx={[styleDialogPaddingX, styleDialogPaddingY]}>{props.children}</DialogContent>

      <DialogActions sx={[styleDialogPaddingX, { paddingTop: 0, paddingBottom: "1rem" }]}>
        <Stack spacing={1.5} direction="row-reverse">
          <Button
            variant="contained"
            onClick={props.primaryButtonAction ? props.primaryButtonAction : props.close}
            data-testid={`${testId.button.primary}-${testId.button.dialogMessage}-${props.dataTestIdString}`}
            color={props.primaryButtonColor}
            startIcon={props.primaryButtonIcon ? <Icon name={props.primaryButtonIcon} /> : null}
            disabled={props.primaryButtonLoader || props.primaryButtonDisabled}
            endIcon={props.primaryButtonLoader ? <LoadingIndicator size={18} /> : null}
          >
            <FormattedMessage id={props.primaryButtonText} />
          </Button>
          {props.secondaryButtonText && (
            <Button
              variant={props.secondaryButtonVariant ? props.secondaryButtonVariant : "outlined"}
              onClick={props.secondaryButtonAction ? props.secondaryButtonAction : props.close}
              data-testid={`${testId.button.secondary}-${testId.button.dialogMessage}-${props.dataTestIdString}`}
              startIcon={props.secondaryButtonIcon ? <Icon name={props.secondaryButtonIcon} /> : null}
              color={props.secondaryButtonColor}
            >
              <FormattedMessage id={props.secondaryButtonText} />
            </Button>
          )}
          {!props.noCancelButton && (
            <Button
              variant="text"
              color="info"
              onClick={props.close}
              data-testid={`${testId.button.cancel}-${testId.button.dialogMessage}-${props.dataTestIdString}`}
            >
              <FormattedMessage id={"common.cancel"} />
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

interface IDialogMenuProps extends DialogProps {
  close: () => void;
  dataTestIdString?: string;
  divider?: boolean;
}
export const DialogMenu: React.FC<IDialogMenuProps> = (props) => {
  const { isTablet } = useMediaQueries();

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted={props.keepMounted ?? true}
      closeAfterTransition
      onClose={props.close}
      sx={styleDialog(isTablet)}
    >
      <DialogHeader close={props.close} title={props.title} divider={props.divider} />
      <DialogContent sx={{ padding: 0 }}>{props.children}</DialogContent>
    </Dialog>
  );
};
