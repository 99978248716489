import { Home } from "layout/home";
import { Progress } from "layout/progress";
import { ProgressTestResults } from "layout/progress/allTestResults";
import { TestResult as FinalTestResult } from "layout/test/final/testResult";
import { FinalTestResult as ProgressFinalTestResult } from "layout/progress/finalTestResult";
import { ProgressPerTopic } from "layout/progress/progressPerTopic";
import { More } from "layout/more";
import { Bookmarks } from "layout/more/bookmark/bookmarks";
import { BookmarkDetail } from "layout/more/bookmark/bookmarkDetail";
import { Agreements } from "layout/more/agreements";
import { Checklist } from "layout/more/checklist";
import { Education } from "layout/more/education/education";
import { Glossary } from "layout/more/glossary";
import { GlossaryDetail } from "layout/more/glossaryDetail";
import { Language } from "layout/more/language";
import { Profile } from "layout/more/profile";
import { Settings } from "layout/more/settings";
import { Support } from "layout/more/support";
import { RoadSigns } from "layout/roadSigns";
import { Test } from "layout/test";
import { TestCustom } from "layout/test/custom";
import { Theory } from "layout/theory";
import { TheoryAnswer } from "layout/theory/answer";
import { TheoryChapters } from "layout/theory/chapters";
import { TheoryPractice } from "layout/theory/practice";
import { TheoryRead } from "layout/theory/read";
import { ReactNode } from "react";
import { useAppIntl } from "services/useAppIntl";
import {
  iconList,
  iconMessageQuestion,
  iconSettingsSolid,
  iconTriangleSolid,
  iconUserSolid,
  iconRoute,
  IconProp,
} from "theme/icons";
import { TestResult } from "layout/test/testResult";
import { TestFinal } from "layout/test/final";
import { TestQuick } from "layout/test/quick";
import { SelectRole, Start } from "layout/start";
import { MyEducation } from "layout/more/myEducation";
import { GetStarted } from "layout/more/getStarted";
import EducationCard from "layout/educationcard";
import Educator from "layout/educator";
import EducatorGroup from "layout/educator/group";
import EducatorGroupStudents from "layout/educator/group/students";
import StudentAdd from "layout/educator/student/add";
import StudentEdit from "layout/educator/student/edit";
import TestResultQuestion from "layout/test/testResultQuestion";

export type RouteKey =
  | "home"
  | "start"
  | "selectRole"
  | "theory"
  | "progress"
  | "more"
  | "theoryRead"
  | "theoryPractice"
  | "theoryAnswer"
  | "theoryChapters"
  | "test"
  | "education"
  | "profile"
  | "checklist"
  | "glossary"
  | "glossaryDetail"
  | "bookmarks"
  | "bookmarksDetail"
  | "support"
  | "language"
  | "settings"
  | "roadsigns"
  | "agreement"
  | "myeducation"
  | "getstarted"
  | "educator";

export type SubRouteKey =
  | "final"
  | "custom"
  | "result"
  | "testresults"
  | "progresspertopic"
  | "finaltestresult"
  | "educationcard";

type Routes = {
  [key in RouteKey]: RouteObject;
};

export type RouteObject = {
  path: string;
  component: ReactNode;
  name: string;
  icon?: IconProp;
  routes?: { [key: string]: RouteObject };
};

//We need to use this path outside of react-intl context
export const MORE_EDUCATION_PATH = "/education";

export const useRouteConfiguration = () => {
  const intl = useAppIntl();

  const studyHelpRoutes = {
    result: {
      path: "result/:id",
      component: <TestResult />,
      name: intl.formatMessage({ id: "common.title.test.result" }),
    },
    resultQuestion: {
      path: "result/:id/question/:questionId",
      component: <TestResultQuestion />,
      name: intl.formatMessage({ id: "common.title.test.result" }),
    },
  };

  const RouteConfiguration: Routes = {
    home: {
      path: "/",
      component: <Home />,
      name: intl.formatMessage({ id: "common.title.home" }),
    },
    start: {
      path: "/start",
      component: <Start />,
      name: "",
    },
    selectRole: {
      path: "/select-role",
      component: <SelectRole />,
      name: "Välj roll",
    },
    theory: {
      path: "/theory",
      component: <Theory />,
      name: intl.formatMessage({ id: "common.title.theory" }),
      routes: {
        ...studyHelpRoutes,
      },
    },
    progress: {
      path: "/progress",
      component: <Progress />,
      name: intl.formatMessage({ id: "common.title.progress" }),
      routes: {
        progresspertopic: {
          path: "progresspertopic",
          component: <ProgressPerTopic />,
          name: intl.formatMessage({ id: "progress.knowledgelevel.per.topic.title" }),
        },
        finaltestresult: {
          path: "finaltestresult",
          component: <ProgressFinalTestResult />,
          name: intl.formatMessage({ id: "test.start.final" }),
        },
        educationcard: {
          path: "educationcard",
          component: <EducationCard type="user" />,
          name: intl.formatMessage({ id: "common.title.education-card" }),
        },
        results: {
          path: "results",
          component: <ProgressTestResults />,
          name: intl.formatMessage({ id: "progress.testresults.title" }),
        },
        result: {
          path: "results/:id",
          component: <TestResult />,
          name: intl.formatMessage({ id: "common.title.test.result" }),
        },
        resultQuestion: {
          path: "results/:id/question/:questionId",
          component: <TestResultQuestion />,
          name: intl.formatMessage({ id: "common.title.test.result" }),
        },
      },
    },

    more: {
      path: "/more",
      component: <More />,
      name: intl.formatMessage({ id: "common.title.more" }),
    },

    theoryRead: {
      path: "/theory/:areaId/:chapterId/read",
      component: <TheoryRead />,
      name: intl.formatMessage({ id: "common.title.read" }),
    },
    theoryPractice: {
      path: "/theory/:areaId/:chapterId/practice",
      component: <TheoryPractice />,
      name: intl.formatMessage({ id: "common.title.practice" }),
    },
    theoryAnswer: {
      path: "/theory/:areaId/:chapterId/answer",
      component: <TheoryAnswer />,
      name: intl.formatMessage({ id: "common.title.answer" }),
    },
    theoryChapters: {
      path: "/theory/:areaId/",
      component: <TheoryChapters />,
      name: "",
    },

    test: {
      path: "/test",
      component: <Test />,
      name: intl.formatMessage({ id: "common.title.test" }),
      routes: {
        final: {
          path: "final",
          component: <TestFinal />,
          name: intl.formatMessage({ id: "common.title.test.final" }),
        },
        custom: {
          path: "custom",
          component: <TestCustom />,
          name: intl.formatMessage({ id: "common.title.test.custom" }),
        },
        quick: {
          path: "quick/:type",
          component: <TestQuick />,
          name: intl.formatMessage({ id: "common.title.test.quick" }),
        },
        finaltestresult: {
          path: "finaltestresult",
          component: <FinalTestResult />,
          name: intl.formatMessage({ id: "test.start.final" }),
        },
        ...studyHelpRoutes,
      },
    },
    education: {
      path: MORE_EDUCATION_PATH,
      component: <Education />,
      name: intl.formatMessage({ id: "more.title.education" }),
    },
    profile: {
      path: "/more/profile",
      component: <Profile />,
      name: intl.formatMessage({ id: "more.title.profile" }),
      icon: iconUserSolid,
    },
    checklist: {
      path: "/more/checklist",
      component: <Checklist />,
      name: intl.formatMessage({ id: "more.title.checklist" }),
    },
    glossary: {
      path: "/more/glossary",
      component: <Glossary />,
      name: intl.formatMessage({ id: "more.title.glossary" }),
      icon: iconList,
    },
    glossaryDetail: {
      path: "/more/glossary/:id",
      component: <GlossaryDetail />,
      name: "",
    },
    bookmarks: {
      path: "/more/bookmarks",
      component: <Bookmarks />,
      name: intl.formatMessage({ id: "more.title.bookmarks" }),
    },
    bookmarksDetail: {
      path: "/more/bookmarks/:id",
      component: <BookmarkDetail />,
      name: "",
    },
    support: {
      path: "/more/support",
      component: <Support />,
      name: intl.formatMessage({ id: "more.title.support" }),
      icon: iconMessageQuestion,
    },
    language: {
      path: "/more/language",
      component: <Language />,
      name: intl.formatMessage({ id: "more.title.language" }),
    },
    settings: {
      path: "/more/settings",
      component: <Settings />,
      name: intl.formatMessage({ id: "more.title.settings" }),
      icon: iconSettingsSolid,
    },
    roadsigns: {
      path: "/more/roadsigns",
      component: <RoadSigns />,
      name: intl.formatMessage({ id: "common.roadsigns" }),
      icon: iconTriangleSolid,
    },
    agreement: {
      path: "/more/agreements",
      component: <Agreements />,
      name: "",
    },
    myeducation: {
      path: "/more/myeducation",
      component: <MyEducation />,
      name: intl.formatMessage({ id: "more.title.myeducation" }),
      icon: iconRoute,
    },
    getstarted: {
      path: "/more/getstarted",
      component: <GetStarted />,
      name: intl.formatMessage({ id: "more.title.getstarted" }),
    },
    educator: {
      path: "/",
      component: <Educator />,
      name: intl.formatMessage({ id: "common.title.educator" }),
      routes: {
        group: {
          path: "group/:groupId",
          component: <EducatorGroup />,
          name: "Gruppens namn",
        },
        educationcard: {
          path: "group/:groupId/education/:educationId",
          component: <EducationCard type="education" />,
          name: intl.formatMessage({ id: "common.title.education-card" }),
        },
        students: {
          path: "group/:groupId/students",
          component: <EducatorGroupStudents />,
          name: "Förare",
        },
        studentEdit: {
          path: "group/:groupId/students/:studentId",
          component: <StudentEdit />,
          name: "Förarens namn",
        },
        studentAdd: {
          path: "group/:groupId/students/add",
          component: <StudentAdd />,
          name: "Ny förare",
        },
        studentEducationcard: {
          path: "group/:groupId/students/:studentId/education/:educationId",
          component: <EducationCard type="student" />,
          name: intl.formatMessage({ id: "common.title.education-card" }),
        },
        studentEducationcardView: {
          path: "group/:groupId/education/:educationId/students/:studentId",
          component: <EducationCard type="student" />,
          name: intl.formatMessage({ id: "common.title.education-card" }),
        },
      },
    },
  };

  return RouteConfiguration;
};
