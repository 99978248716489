import { EventType, InteractionStatus, InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { ADB2C_ERROR_CODES, b2cPolicies } from "authConfig";
import useLocalStorage from "hooks/useLocalStorage";
import React, { PropsWithChildren, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { passwordChangedSuccess, userStateAccount } from "state/userState";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useLogout } from "hooks/useLogout";
import { MemberRole } from "types/user-account";
import { AppIntro } from "layout/appIntro";

interface ILoginHandlerProps {
  instance: IPublicClientApplication;
}
type loginStatus = "authenticated" | "unauthorized" | "none";
export const LoginHandler: React.FC<PropsWithChildren<ILoginHandlerProps>> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [status, setStatus] = React.useState<loginStatus>("none");
  const [lsChangePassword, removeLsChangePassword] = useLocalStorage("profileChangePassword");
  const setPasswordChanged = useSetRecoilState(passwordChangedSuccess);
  const [userAccountState, setUserAccountState] = useRecoilState(userStateAccount);
  const [, setShowLsRegisterEducatorWarning] = useLocalStorage("showRegisterEducatorWarning");
  const { logout } = useLogout();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginFailure = (event: any) => {
    ReactGA.event({
      category: "login",
      action: "loginFailure",
    });
    if (event.error.errorMessage.includes(ADB2C_ERROR_CODES.USER_CANCELLATION)) instance.loginRedirect();

    if (event.error && event.error.errorMessage.indexOf(ADB2C_ERROR_CODES.FORGOTTEN_PASSWORD) > -1) {
      if (event.interactionType === InteractionType.Redirect)
        return instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
    }

    return logout();
  };

  const handleLoginSuccess = (event: any) => {
    ReactGA.event({
      category: "login",
      action: "loginSuccess",
    });
    if (event?.payload) {
      /**
       * We need to reject id tokens that were not issued with the default sign-in policy.
       * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
       * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
       */
      if (event.payload.idTokenClaims["tfp"] === b2cPolicies.names.forgotPassword) {
        /**
         * Check for key in localStorage.
         * If found we are coming from "change password" on Profile page
         * and we can (safely) remain logged in. If not, force a logout
         */
        if (!lsChangePassword) return logout("forgotPassword");

        removeLsChangePassword();
        setPasswordChanged(true);
        return;
      }

      if (event.payload.account) {
        // Since the user only has one account, set that to active
        instance.setActiveAccount(event.payload.account);
      } else {
        const account = instance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          instance.loginRedirect();
        }
      }

      /**
       * If it's a new user
       */
      // if (event.payload.idTokenClaims["newUser"]) return navigate(NEW_ACCOUNT_PATH);

      /**
       * Existing user -> navigate to start (which decides the initial page for the user)
       */
      return navigate("/start");
    }
  };

  const setRoles = () => {
    const account = instance.getActiveAccount();
    if (!account) return null;

    let appRoles = account.idTokenClaims?.extension_AppRoles as string;

    console.log("appRoles", appRoles, "userAccountState", userAccountState, "claims", account.idTokenClaims);
    const roles: MemberRole[] = [];

    // If user has one role set in state, we should use them
    if (userAccountState && userAccountState?.roles.length === 1) return userAccountState.roles;

    appRoles.split(",").forEach((role) => {
      role = role.trim();
      if (role === MemberRole.CustomerAdmin || role === MemberRole.UnitAdmin || MemberRole.None) return;

      roles.push(role as MemberRole);
    });

    // roles.push(MemberRole.Educator);

    console.log("userRoles", roles);
    // If user has no roles, we should not allow access
    if (roles.length === 0) return null;

    setUserAccountState({
      name: `${account.idTokenClaims?.extension_FirstName as string} ${
        account.idTokenClaims?.extension_LastName as string
      }`,
      firstName: (account.idTokenClaims?.extension_FirstName as string) ?? "Förnamn",
      lastName: (account.idTokenClaims?.extension_LastName as string) ?? "Efternamn",
      roles: roles,
      customerId: account.idTokenClaims?.extension_CustomerId as string, // ?? "459f085b-a7d5-4e8b-8695-08dc6add5a5e",
      actorId: account.idTokenClaims?.extension_ActorId as string,
    });

    return roles;
  };

  useEffect(() => {
    if (isAuthenticated) {
      const roles = setRoles();

      if (!roles || roles.length === 0) {
        setStatus("unauthorized");
        instance.loginRedirect();
        return;
      }

      if (roles.length > 1) return navigate("/select-role");

      setStatus("authenticated");
    } else {
      setStatus("unauthorized");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        return handleLoginFailure(event);
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        setShowLsRegisterEducatorWarning(true);

        return handleLoginSuccess(event);
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        if (event.error?.errorMessage?.indexOf(ADB2C_ERROR_CODES.USER_CANCELLATION) > -1) {
          // If we get here user has canceled password reset from my profile page
          // and we don't want to take action on that
          return;
        }

        return logout();
      }
    });

    return () => {
      if (callbackId) instance.removeEventCallback(callbackId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When msal is in progress we don't want to show the app
  if (status === "none" || inProgress !== InteractionStatus.None) {
    if (process.env.NODE_ENV === "development")
      console.log("LoginHandler: Msal in progress...", inProgress, location.hash, "isAuthenticated", isAuthenticated);

    // TODO: Vi kan inte använda useAppIntl här (och översättningar), då den inte är tillgänglig än. Måste hitta en annan lösning.

    let text: string = "Autentiserar...";
    if (inProgress === InteractionStatus.Startup) text = "Initierar...";
    if (inProgress === InteractionStatus.Login) text = "Loggar in dig i FM Optima";
    if (inProgress === InteractionStatus.Logout) text = "Loggar ut...";

    return <AppIntro text={text} />;
  }

  return <>{children}</>;
};
