import { useState } from "react";
import { Icon, iconAward } from "theme/icons";
import { Stack, Typography } from "@mui/material";
import Status from "./Status";
import ItemContainer from "./ItemContainer";
import Comment from "./Comment/Comment";
import FormSwitch from "utils/helpers/FormSwitch";
import useRoles from "hooks/useRoles";
import { LoadingStatus } from "types/request";
import { useRecoilValue } from "recoil";
import { educationCardPropsState } from "state/educationCardState";
import { EducationCardActivity } from "types/educationCard";
import AccordionEducationCard from "layout/Components/AccordionEducationCard";
import Students from "./Students";
import { delay } from "services/educationCardService";

const ItemTestTheoretical = ({ item }: { item: EducationCardActivity }) => {
  // const { item, upsertListItem } = useEducationCardListItem(id);
  const [status, setStatus] = useState<LoadingStatus>("none");
  const { isEducator } = useRoles();
  const state = useRecoilValue(educationCardPropsState);

  if (!state) throw new Error("No education card state found");

  // Approve all learningObjectives in this item
  async function handleApproveAll(checked: boolean) {
    if (!state) throw new Error("No education card state found");

    console.log("Approving", item.title);
    setStatus("pending");

    // collect all children
    // let theoreticalRefKeys = item.theoryItems?.map((i) => i.referenceKey) || [];
    // let updatedItem = await setApproved(
    //   state.eventId,
    //   item.rootKey,
    //   item.parentKey,
    //   theoreticalRefKeys,
    //   state.userIds,
    //   "theoretical",
    //   checked,
    //   item.learningObjectives,
    //   state.identifier
    // );

    // let practicalRefKeys = item.practiceItems?.map((i) => i.referenceKey) || [];
    // updatedItem = await setApproved(
    //   state.eventId,
    //   item.rootKey,
    //   item.parentKey,
    //   practicalRefKeys,
    //   state.userIds,
    //   "practical",
    //   checked,
    //   item.learningObjectives,
    //   state.identifier
    // );

    // TODO: handle errors...

    // upsertListItem(updatedItem);

    setStatus("none");
    console.log(item.title, "is", checked ? "approved" : "not approved");
  }

  async function handleUnlock(checked: boolean) {
    if (!state) throw new Error("No education card state found");

    console.log("Unlocking", item.title);
    setStatus("pending");

    delay(1200).then(() => setStatus("none"));

    console.log(item.title, "is", checked ? "unlocked" : "locked");
  }

  return (
    <AccordionEducationCard
      id={item.id}
      status={status}
      summary={
        <ItemContainer>
          <Stack spacing={1} direction="row" alignItems="center">
            <Icon name={iconAward} color="info" />
            <Typography variant="subtitle2">{item.title}</Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            {isEducator ? (
              <>
                <Status status={item.status} />
                <FormSwitch label="Godkänd" checked={item.approved} onChange={handleApproveAll} />
                <FormSwitch label="Lås upp" checked={item.approved} onChange={handleUnlock} />
                {state.type !== "education" && <Comment hasComment={false} noButton />}
              </>
            ) : (
              <Status status={item.status} />
            )}
            {state.type === "education" && <Students item={item} />}
          </Stack>
        </ItemContainer>
      }
      details={
        <Stack></Stack>
        // <Stack direction={"column"} spacing={4}>
        //   <LearningObjectives items={item.theoryItems} type="test" status="Resultat">
        //     {item.theoryItems?.map((subItem) => (
        //       <LearningObjectiveTest key={subItem.id} item={subItem} />
        //     ))}
        //   </LearningObjectives>
        // </Stack>
      }
    />
  );
};

export default ItemTestTheoretical;
