import { PropsWithChildren } from "react";
import { AppBar, Avatar, Box, Divider, Toolbar, Stack, Typography, IconButton } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { userStateAccount } from "state/userState";
import { InfoMessage } from "utils/helpers/InfoMessage";
import HeaderEducationMenu from "utils/helpers/dropdown/HeaderEducationMenu";
import { Icon, iconLogout } from "theme/icons";
import { HeaderDesktopMenu } from "./headerDesktopMenu";
import { desktopWidth420 } from "theme/theme";
import { useFetchFeatureFlag } from "@strmediaochitab/optima-react-components";
import { maintenanceState } from "state/recoilAtoms";
import logo from "static/logo.png";
import useRoles from "hooks/useRoles";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { useLogout } from "hooks/useLogout";
import useMediaQueries from "hooks/useMediaQueries";

type HeaderProps = {
  hideEducations?: boolean;
};
export const Header = (props: HeaderProps) => {
  const { isDesktop } = useMediaQueries();
  const [showMaintenanceMessage, setShowMaintenanceMessage] = useRecoilState(maintenanceState);
  const maintenanceMessage = useFetchFeatureFlag("MaintenanceMessage");

  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          {props.hideEducations ? <FormattedTypography id="common.app.title" variant="h4" /> : <HeaderEducationMenu />}

          {isDesktop && <HeaderDesktopMenu />}

          <Box display="flex" justifyContent="flex-end" width="220px" alignItems="center" columnGap={1.5}>
            <UserInfo />
          </Box>
        </Box>
        <Divider />
      </Toolbar>

      {maintenanceMessage && showMaintenanceMessage && (
        <HeaderInfoMessage>
          <InfoMessage
            type="info"
            message="common.app.maintenance.info"
            closeButton={() => setShowMaintenanceMessage(false)}
            showCloseButton={true}
            closeButtonColor="white"
          />
        </HeaderInfoMessage>
      )}
    </AppBar>
  );
};

const HeaderInfoMessage = ({ children }: PropsWithChildren) => {
  const { isMobile } = useMediaQueries();

  return (
    <Box
      position={"fixed"}
      zIndex={10001}
      marginTop="75px"
      width={isMobile ? "100%" : desktopWidth420}
      marginLeft={isMobile ? 0 : `calc(100vw - ${desktopWidth420} - 16px)`}
      paddingLeft={2}
      paddingRight={2}
    >
      {children}
    </Box>
  );
};

const UserInfo = () => {
  // const userAvatar = useRecoilValue(userStateAvatar);
  const userAccount = useRecoilValue(userStateAccount);
  const { isEducator } = useRoles();
  const { logout } = useLogout();

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <IconButton color="error" onClick={() => logout()}>
        <Icon name={iconLogout} />
      </IconButton>
      <Stack alignItems={"end"}>
        <Typography variant="subtitle1" fontSize={12}>
          {userAccount?.name}
        </Typography>
        <Typography variant="captionImage">{isEducator ? "Instruktör" : "Förare"}</Typography>
      </Stack>
      {/* {userAvatar ? <Avatar src={userAvatar.src} /> : <Icon name={iconUserSolid} htmlColor="white" />} */}

      <Avatar src={logo} variant="rounded" sx={{ backgroundColor: "#FFFFFF", "> img": { objectFit: "contain" } }} />
    </Stack>
  );
};
