import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { EducationCard, EducationCardActivity, EducationCardProgress, Status } from "types/educationCard";
import { API_URL_LRS_XAPI, API_URL_SAS } from "utils/constants";
import { get, post } from "./httpService";
import { v5 as uuidv5 } from "uuid";
import { createAndSendProgressStatement } from "./xapiService";

const apiLrsXapiUrl = API_URL_LRS_XAPI;
const apiSasUrl = API_URL_SAS;

type Aggregate = {
  contentId: string;
  versionId: string;
  activities: AggregateActivity[];
  progress: AggregateProgress[];
};

type AggregateActivity = {
  id: number;
  parentId: number;
  identifier: string;
  referenceKey: ReferenceKey;
  name: string;
  learningObjectives: AggregateLearningObjective[];
};

type AggregateProgress = {
  type: "None" | "Practical" | "Theoretical";
  min: number;
  max: number;
  raw: number;
};

type AggregateLearningObjective = {
  identifier: string;
  type: "None" | "Practical" | "Theoretical";
  status: "None" | "Started" | "Fullfilled";
  actorStatus: object;
};

type ApprovalState = {
  comments: Comment;
  theoretical: string[];
  practical: string[];
};

type Comment = {};

export type ResultStatus = "None" | "Ongoing" | "Success" | "Fail";
export type EducationCardKey = {
  userId: string;
};

// TEMP
export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const getStatus = (aLos: AggregateLearningObjective[]): Status => {
  if (aLos.filter((x) => x.status === "Fullfilled").length === aLos.length) return "Success";
  if (aLos.filter((x) => x.status === "Fullfilled").length > 0 || aLos.filter((x) => x.status === "Started").length > 0)
    return "Ongoing";
  return "None";
};

const mapStudentStatus = (value: any): Status => {
  if (value === "Fullfilled") return "Success";
  if (value === "Started") return "Ongoing";
  return "None";
};

const getStudentStatusAndApproval = (
  aLos: AggregateLearningObjective[],
  state?: string[]
): Map<string, { status: Status; approved: boolean }> => {
  const map = new Map<string, { status: Status; approved: boolean }>();

  aLos.forEach((x) => {
    for (const [k, v] of Object.entries(x.actorStatus)) {
      let status = mapStudentStatus(v);

      if (
        (status === "Success" && map.has(k) && map.get(k)?.status !== "Success") ||
        (status === "None" && map.has(k) && map.get(k)?.status === "Ongoing")
      ) {
        status = "Ongoing";
      }

      map.set(k, { status: status, approved: state?.includes(k) ?? false });
    }
  });

  return map;
};

const getChapterStatus = (theoryItems: EducationCardActivity[]): Status => {
  const statuses = theoryItems.map((x) => x.status);
  if (statuses.filter((x) => x === "Success").length === statuses.length) return "Success";
  if (statuses.includes("Success") || statuses.includes("Ongoing")) return "Ongoing";
  return "None";
};

const getChapterStudentStatusAndApproval = (
  theoryItems: EducationCardActivity[]
): Map<string, { status: Status; approved: boolean }> => {
  const map = new Map<string, { status: Status; approved: boolean }>();

  theoryItems
    .map((x) => x.studentStatus)
    .forEach((n) =>
      n?.forEach((v, k) => {
        let approved = v.approved;
        let status = v.status;

        if (map.has(k) && map.get(k)!.approved && !approved) {
          approved = false;
        }

        if (
          (status === "Success" && map.has(k) && map.get(k)?.status !== "Success") ||
          (status === "None" && map.has(k) && (map.get(k)?.status === "Success" || map.get(k)?.status === "Ongoing"))
        ) {
          status = "Ongoing";
        }

        map.set(k, { status: status, approved: approved });
      })
    );

  return map;
};

const mapEducationCardData = (
  data: Aggregate,
  approvaleState: Map<string, ApprovalState>,
  userIds: string[],
  educationCardIdentifier: string
): EducationCardActivity[] => {
  const root = data.activities.find((x) => x.parentId == 0);
  const areas = data.activities.filter((x) => x.parentId === root?.id);
  const chapters = data.activities.filter((x) => areas.map((y) => y.id).includes(x.parentId));

  const rootKey = { contentId: data.contentId, versionId: data.versionId };

  const NS_PRACTICAL = "710ca5fc-1244-409d-bed2-a01f22bc3be6";
  const NS_THEORETICAL = "b6af4fee-88eb-4eef-b9ed-33dfc4dc5b1b";

  return chapters.map((chapter) => {
    const sections = data.activities.filter((x) => chapter.id === x.parentId);

    const practical = sections.filter((x) => x.learningObjectives.some((y) => y.type === "Practical"));
    const theoretical = sections.filter((x) => x.learningObjectives.some((y) => y.type === "Theoretical"));

    const practiceItems: EducationCardActivity[] = practical?.map((practiceItem) => {
      const state = approvaleState.get(practiceItem.referenceKey.versionId)?.practical;
      return {
        id: uuidv5(practiceItem.identifier, NS_PRACTICAL),
        referenceKey: practiceItem.referenceKey,
        parentKey: chapter.referenceKey,
        rootKey: rootKey,
        title: practiceItem.name,
        status: getStatus(practiceItem.learningObjectives.filter((x) => x.type === "Practical")),
        approved: userIds.every((x) => state?.includes(x)) ? true : false,
        comments: [],
        learningObjectives: practiceItem.learningObjectives
          .filter((x) => x.type === "Practical")
          .map((x) => x.identifier),
        studentStatus: getStudentStatusAndApproval(
          practiceItem.learningObjectives.filter((x) => x.type === "Practical"),
          state
        ),
      };
    });

    const theoryItems: EducationCardActivity[] = theoretical!.map((theoryItem) => {
      const state = approvaleState.get(theoryItem.referenceKey.versionId)?.theoretical;

      return {
        id: uuidv5(theoryItem.identifier, NS_THEORETICAL),
        referenceKey: theoryItem.referenceKey,
        parentKey: chapter.referenceKey,
        rootKey: rootKey,
        title: theoryItem.name,
        status: getStatus(theoryItem.learningObjectives.filter((x) => x.type === "Theoretical")),
        approved: userIds.every((x) => state?.includes(x)) ? true : false,
        comments: [],
        learningObjectives: theoryItem.learningObjectives
          .filter((x) => x.type === "Theoretical")
          .map((x) => x.identifier),
        studentStatus: getStudentStatusAndApproval(
          theoryItem.learningObjectives.filter((x) => x.type === "Theoretical"),
          state
        ),
      };
    });

    return {
      id: educationCardIdentifier + "-" + chapter.referenceKey.versionId, // uuidv4(),
      referenceKey: chapter.referenceKey,
      parentKey: chapter.referenceKey,
      rootKey: rootKey,
      title: chapter.name,
      status: getChapterStatus(theoryItems),
      approved: practiceItems!.filter((x) => !x.approved).length + theoryItems!.filter((x) => !x.approved).length === 0,
      practiceItems: practiceItems,
      theoryItems: theoryItems,
      studentStatus: getChapterStudentStatusAndApproval(theoryItems),
    };
  });
};

const getApprovalState = async (eventId: string): Promise<Map<string, ApprovalState>> => {
  const userId = eventId;
  const contentId = eventId;
  const versionId = eventId;
  const stateId = eventId;

  const url = apiLrsXapiUrl + `state/${userId}/${contentId}/${versionId}/${stateId}`;

  const response: any = await get(url);

  if (response.status && response.status === 404) return new Map<string, ApprovalState>();

  return new Map<string, ApprovalState>(Object.entries(response));
};

const postApprovalState = async (eventId: string, state: Map<string, ApprovalState>): Promise<void> => {
  const userId = eventId;
  const contentId = eventId;
  const versionId = eventId;
  const stateId = eventId;

  const url = apiLrsXapiUrl + `state/${userId}/${contentId}/${versionId}/${stateId}`;

  await post(url, JSON.stringify(Object.fromEntries(state)));
};

const _getEducationCard = (
  structure: EducationCardActivity[],
  aggregate: Aggregate,
  userIds: string[]
): EducationCardProgress => {
  const decimals = 0;
  const practByInstructor =
    structure
      .flatMap((x) => x.practiceItems)
      .filter((x) => x?.approved)
      .flatMap((x) => x?.learningObjectives).length * userIds.length;
  const theoByInstructor =
    structure
      .flatMap((x) => x.theoryItems)
      //.filter((x) => x?.approved)
      .filter((x) => x?.approved && (x?.status === "None" || x?.status === "Fail" || x?.status === "Ongoing"))
      .flatMap((x) => x?.learningObjectives).length * userIds.length;

  let rPract = parseFloat(
    ((practByInstructor / aggregate.progress.find((x) => x.type === "Practical")!.max) * 100).toFixed(decimals)
  );

  let rTheoStud = parseFloat(
    (
      (aggregate.progress.find((x) => x.type === "Theoretical")!.raw /
        aggregate.progress.find((x) => x.type === "Theoretical")!.max) *
      100
    ).toFixed(decimals)
  );

  let rTheoInstr = parseFloat(
    (
      ((aggregate.progress.find((x) => x.type === "Theoretical")!.raw + theoByInstructor) /
        aggregate.progress.find((x) => x.type === "Theoretical")!.max) *
      100
    ).toFixed(decimals)
  );

  if (isNaN(rPract)) rPract = 0;
  if (isNaN(rTheoStud)) rTheoStud = 0;
  if (isNaN(rTheoInstr)) rTheoInstr = 0;

  return { practical: rPract, theoretical: rTheoStud, theoreticalByInstructor: rTheoInstr };
};

// Exports
export const getEducationCard = async (
  eventId: string,
  key: ReferenceKey,
  userIds: string[],
  educationCardIdentifier: string
): Promise<EducationCard> => {
  console.log("ecService getting education card...", eventId, key, userIds);

  //await delay(2000);

  const url = apiSasUrl + `${key.contentId}/${key.versionId}?${userIds.map((x) => `actorIds=${x}`).join("&")}`;
  const aggregate: Aggregate = await get(url);

  let numFullfilled = 0;

  const test = aggregate.activities.flatMap((item) => {
    item.learningObjectives.flatMap((x) => {
      const fullFilled = Object.values(x.actorStatus).includes("Fullfilled");
      if (fullFilled) {
        numFullfilled++;
      }
    });
  });

  // var pract = test.flatMap((x) => x.learningObjectives.filter((x) => x.type === "Practical"));
  // var practFf = pract.filter((x) => x.status === "Fullfilled");

  // var teo = test.flatMap((x) => x.learningObjectives.filter((x) => x.type === "Theoretical"));
  // var teoFf = pract.filter((x) => x.status === "Fullfilled");

  // var rPract = (20 / pract.length) * 100;
  // var rTeo = (20 / teo.length) * 100;
  // var rTotal = (40 / (pract.length + teo.length)) * 100;

  const approvalState = await getApprovalState(eventId);

  const structure = mapEducationCardData(aggregate, approvalState, userIds, educationCardIdentifier);

  // const practByInstructor = structure
  //   .flatMap((x) => x.practiceItems)
  //   .filter((x) => x?.approved)
  //   .flatMap((x) => x?.learningObjectives).length * userIds.length;
  // const theoByInstructor = structure
  //   .flatMap((x) => x.theoryItems)
  //   //.filter((x) => x?.approved)
  //   .filter((x) => x?.approved && (x?.status === "None" || x?.status === "Fail" || x?.status === "Ongoing"))
  //   .flatMap((x) => x?.learningObjectives).length * userIds.length;

  // const rPract = Math.round(practByInstructor / aggregate.progress.find(x => x.type === "Practical")!.max * 100);
  // const rTheoInstr = Math.round(theoByInstructor / aggregate.progress.find(x => x.type === "Theoretical")!.max * 100);
  // const rTheoStud = Math.round((aggregate.progress.find(x => x.type === "Theoretical")!.raw + theoByInstructor) / aggregate.progress.find(x => x.type === "Theoretical")!.max * 100);

  const progress = _getEducationCard(structure, aggregate, userIds);

  return { activities: structure, progress: progress };
};

export const setApproved = async (
  eventId: string, // group id
  rootKey: ReferenceKey,
  parentKey: ReferenceKey,
  keys: ReferenceKey[],
  userIds: string[],
  groupUserIds: string[],
  learningObjectiveType: "theoretical" | "practical",
  approved: boolean,
  learningObjectives: string[] | undefined,
  educationCardIdentifier: string
): Promise<EducationCard> => {
  const approvalState = await getApprovalState(eventId);

  keys.forEach((key) => {
    if (!approvalState.has(key.versionId))
      approvalState.set(key.versionId, { comments: {}, theoretical: [], practical: [] });

    if (learningObjectiveType === "theoretical") {
      approvalState.get(key.versionId)!.theoretical = approvalState
        .get(key.versionId)!
        .theoretical.filter((x) => !userIds.includes(x));

      if (approved) {
        approvalState.get(key.versionId)!.theoretical = [...approvalState.get(key.versionId)!.theoretical, ...userIds];
      }
    }

    if (learningObjectiveType === "practical") {
      approvalState.get(key.versionId)!.practical = approvalState
        .get(key.versionId)!
        .practical.filter((x) => !userIds.includes(x));

      if (approved) {
        approvalState.get(key.versionId)!.practical = [...approvalState.get(key.versionId)!.practical, ...userIds];
        console.log(`SEND ${learningObjectives?.length} STATEMENTS`);
        if (learningObjectives)
          userIds.forEach(async (userId) => {
            await createAndSendProgressStatement(userId, rootKey, learningObjectives);
          });
      }
    }
  });

  await postApprovalState(eventId, approvalState);

  const url =
    apiSasUrl + `${rootKey.contentId}/${rootKey.versionId}?${groupUserIds.map((x) => `actorIds=${x}`).join("&")}`;
  const aggregate: Aggregate = await get(url);

  const structure = mapEducationCardData(aggregate, approvalState, groupUserIds, educationCardIdentifier);
  const part = structure.find((x) => x.referenceKey.versionId === parentKey.versionId);

  const data = await new Promise<EducationCardActivity>((resolve, reject) => {
    if (part) resolve(part);
    else reject("error: part not found");
  });

  const progress = _getEducationCard(structure, aggregate, groupUserIds);

  return { activities: [data], progress: progress };
};

export const addComment = async (
  eventId: string, // group id
  keys: ReferenceKey[],
  userId: string,
  comment: string,
  shared: boolean
): Promise<boolean> => {
  console.log("ecService adding comment...", eventId, keys, userId, comment, shared);

  await delay(2000);

  const data = await new Promise<boolean>((resolve) => {
    resolve(true);
  });

  return data;
};
