import { PropsWithChildren, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Box, Button, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Icon, iconArrowRightRegular, iconUserGroup } from "theme/icons";
import ApproveDialog from "./ApproveDialog";
import { EducationCardActivity } from "types/educationCard";
import FormSwitch from "utils/helpers/FormSwitch";
import ListHeader from "layout/Components/ListHeader";
import { ContentLoader } from "utils/helpers/LoadingIndicator";
import { educationCardPropsState } from "state/educationCardState";
import { studentsSelector } from "state/educatorState";
import { LearningEventStudent } from "types/learningEvent";
import Status from "./Status";
import { LoadingStatus } from "types/request";
// import StudentEducationcardDialog from "./StudentEducationcardDialog";

const styleButton = {
  height: "24px",
  minWidth: "auto",
  borderRadius: "100px",
};

type StudentsProps = {
  item: EducationCardActivity;
  onApprove?: (
    item: EducationCardActivity,
    type: "theoretical" | "practical",
    checked: boolean,
    userId: string
  ) => Promise<void>;
  onApproveAll?: (checked: boolean, userId?: string) => Promise<void>;
};

const Students = ({ item, onApprove, onApproveAll }: StudentsProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="outlined" size="small" sx={styleButton} onClick={() => setOpen(true)}>
        <Icon name={iconUserGroup} size="small" color="info" />
      </Button>

      {open && (
        <ApproveDialog title={item.title} open={true} close={() => setOpen(false)}>
          <List edit={true} item={item} onApprove={onApprove} onApproveAll={onApproveAll} />
        </ApproveDialog>
      )}
    </>
  );
};

export default Students;

type ListProps = {
  edit: boolean;
  item: StudentsProps["item"];
  onApprove: StudentsProps["onApprove"];
  onApproveAll: StudentsProps["onApproveAll"];
};
function List({ edit, item, onApprove, onApproveAll }: ListProps) {
  const [status, setStatus] = useState<LoadingStatus>("none");
  const state = useRecoilValue(educationCardPropsState);
  const { groupId } = useParams();
  const groupStudents = useRecoilValue(studentsSelector(groupId));

  const students = groupStudents.map((student) => {
    const itemStudent = item.studentStatus?.get(student.id);
    return {
      id: student.id,
      status: itemStudent?.status || "NotStarted",
      approved: itemStudent?.approved || false,
    };
  }) as LearningEventStudent[];

  async function handleApprove(checked: boolean, student: LearningEventStudent) {
    if (!state) throw new Error("No education card state found");

    console.log("approving student", checked, student, item.title);
    setStatus("pending");

    // Approve all chapters
    if (item.theoryItems) {
      // TODO: nullable for demo only
      if (onApproveAll) await onApproveAll(checked, student.id);
    } else {
      // Approve single chapter
      // TODO: nullable for demo only
      if (onApprove) await onApprove(item, "theoretical", checked, student.id);
    }

    setStatus("none");
  }

  return (
    <Box mx={-2} my={-3}>
      <ContentLoader isLoading={status === "pending"} loadingText="Sparar..." />
      <TableContainer sx={{ pb: 1.5 }}>
        <Table size="small" aria-label="Table of students">
          <TableHead>
            <TableRow>
              <TableCell>
                <ListHeader>Förare</ListHeader>
              </TableCell>
              <TableCell width="100">
                <ListHeader>Status</ListHeader>
              </TableCell>
              <TableCell width="100" sx={{ pl: 3 }}>
                <ListHeader>
                  {/* {edit && <FormSwitch label="Alla" checked={false} onChange={handleApproveAll} />} */}
                  Klar
                </ListHeader>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((row) => (
              <ListRow key={row.id} student={row} edit={edit}>
                <FormSwitch checked={row.approved} noLabel onChange={(checked) => handleApprove(checked, row)} />
              </ListRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const ListRow = ({
  student,
  edit,
  children: approveSwitch,
}: { student: LearningEventStudent; edit: ListProps["edit"] } & PropsWithChildren) => {
  const name = `${student.extensions?.firstName} ${student.extensions?.lastName}`;
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          <EducationCardLink studentId={student.id}>
            <span>{name}</span>
          </EducationCardLink>
        </TableCell>
        <TableCell>
          <Status status={student.status} />
        </TableCell>
        <TableCell>{edit ? approveSwitch : "Nej"}</TableCell>
      </TableRow>
    </>
  );
};

const EducationCardLink = ({ children, studentId }: { studentId: string } & PropsWithChildren) => {
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleLinkClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    navigate(`students/${studentId}`);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} onClick={handleLinkClick}>
        {children}
        <Icon name={iconArrowRightRegular} color="info" />
      </Stack>

      {/* {open && (
        <StudentEducationcardDialog
          title="Elevens utbildningskort"
          open={true}
          close={() => setOpen(false)}
          studentId={studentId}
        ></StudentEducationcardDialog>
      )} */}
    </>
  );
};
