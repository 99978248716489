import { ReactNode, FC } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { HeaderNavigation } from "utils/helpers/HeaderNavigation";
import useRoutePath from "hooks/useRoutePath";
import { RouteKey, useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useAppIntl } from "services/useAppIntl";

export interface IHeaderProps {
  children?: ReactNode;
  callback?: () => void;
}

export const Header: FC<IHeaderProps> = (props) => {
  const path = useRoutePath();
  const routes = useRouteConfiguration();
  const intl = useAppIntl();

  // Extract route names
  const paths = path.replace("/", "").split("/");
  const topTitle = routes[paths[0] as RouteKey].name;
  let title = routes[paths[1] as RouteKey].name;

  // TODO: Workaround for now.
  if (path === "/more/educator/find") {
    title = intl.formatMessage({ id: "more.title.find.educator" });
  }

  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar>
        <HeaderNavigation iconSize={"large"} topTitle={topTitle} title={title} onNavigate={props.callback} />
      </Toolbar>
    </AppBar>
  );
};
