import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import useToggle from "hooks/useToggle";
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import { Icon, IconProp, SizeProp } from "theme/icons";
import { LinearProgressIndicator } from "./LinearProgressIndicator";
import { type Variant } from "@mui/material/styles/createTypography";
import { DataTestType } from "tests/test.setup";
import { defaultBorderColor } from "theme/theme";
interface INavigationItemBase {
  children?: ReactNode;
  size?: "large" | "auto";
  href?: string;
  /**
   * Set the hrefTarget to route to an external pages
   */
  hrefTarget?: React.HTMLAttributeAnchorTarget;
  disabled?: boolean;
}

interface INavigationItem extends INavigationItemBase, DataTestType {
  text: string | ReactElement;
  textVariant?: Variant;
  subText?: string | ReactElement;
  subTextVariant?: Variant;
  /**
   * Use an array to toggle an on/off icon state
   */
  icon?: IconProp | IconProp[];
  /**
   * Default value is 'large'
   */
  iconSize?: SizeProp;
  navigationText?: string | JSX.Element;
  navigationIcon?: IconProp;
  progressValue?: number;
  color?: string;
  borderRadius?: string | number;
  width?: string | number;
  height?: string | number;
  showChildren?: boolean;
  onClick?: () => void;
}

export const NavigationItem: React.FC<INavigationItem> = (props) => {
  const theme = useTheme();
  const [showChildren, setShowChildren] = useToggle(false);
  const text =
    typeof props.text == "string" ? (
      <Typography variant={props.textVariant ? props.textVariant : "subtitle1"} noWrap maxWidth="85%">
        {props.text}
      </Typography>
    ) : (
      props.text
    );

  let icon: any;
  if (props.icon instanceof Array) {
    icon = showChildren ? props.icon[1] : props.icon[0];
  } else {
    icon = props.icon;
  }

  useEffect(() => {
    if (props.showChildren === undefined) return;
    setShowChildren(props.showChildren);
  }, [props.showChildren, setShowChildren]);

  const handleShowChildren = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, show: boolean) => {
    e.stopPropagation();
    setShowChildren(show);
  };

  return (
    <ListItem
      component={Box}
      disablePadding
      disableGutters
      onClick={props.onClick}
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: props.borderRadius ?? null,
        height: props.height ?? "auto",
        width: props.width ?? "100%",
        border: `1px solid ${defaultBorderColor}`,
      }}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        {props.progressValue && (
          <LinearProgressIndicator value={props.progressValue - Math.floor(Math.random() * 50)} color="success" />
        )}
        <NavigationItemButton
          href={props.href}
          hrefTarget={props.hrefTarget}
          size={props.size}
          dataTestIdString={props.dataTestIdString}
          disabled={props.disabled}
        >
          {icon && (
            <ListItemIcon
              sx={{ p: "0 16px 0 8px", minWidth: "auto" }}
              onClick={icon.iconName?.includes("chevron") ? (e) => handleShowChildren(e, !showChildren) : props.onClick}
            >
              <Icon
                name={icon}
                htmlColor={props.color ? props.color : theme.palette.info.main}
                size={props.iconSize ? props.iconSize : "large"}
              />
            </ListItemIcon>
          )}
          <ListItemText
            primary={text}
            secondary={
              <Typography variant={props.subTextVariant ?? "body2"} component={"div"}>
                {props.subText}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            {props.navigationIcon && (
              <>
                {props.navigationText}
                <IconButton aria-label="">
                  <Icon name={props.navigationIcon} htmlColor={props.color ? props.color : theme.palette.info.main} />
                </IconButton>
              </>
            )}
          </ListItemSecondaryAction>
        </NavigationItemButton>
        <Box sx={{ display: showChildren ? "block" : "none" }}>{props.children}</Box>
      </Box>
    </ListItem>
  );
};

interface INavigationItemButton extends INavigationItemBase, DataTestType {}

const NavigationItemButton: React.FC<INavigationItemButton> = (props) => {
  const target = useMemo(() => {
    return props.hrefTarget ?? undefined;
  }, [props.hrefTarget]);

  const sx = useMemo(() => {
    return props.size === "large" ? { minHeight: "54px" } : { height: "100%" };
  }, [props.size]);

  const dataTestIdString = useMemo(() => {
    return `${testId.listItem.listItem}-${props.dataTestIdString}`;
  }, [props.dataTestIdString]);

  switch (true) {
    case target && !!props.href:
      return (
        <ListItemButton
          href={props.href!}
          target={target}
          sx={sx}
          data-testid={dataTestIdString}
          disabled={props.disabled}
        >
          {props.children}
        </ListItemButton>
      );
    case !target && !!props.href:
      return (
        <ListItemButton
          component={Link}
          to={props.href!}
          target={target}
          sx={sx}
          data-testid={dataTestIdString}
          disabled={props.disabled}
        >
          {props.children}
        </ListItemButton>
      );

    default:
      return (
        <ListItemButton sx={sx} data-testid={dataTestIdString} disabled={props.disabled}>
          {props.children}
        </ListItemButton>
      );
  }
};
